import React, { useState } from 'react';
import { Container, Grid, Box, Typography, TextField, Button, Paper, List, ListItem, ListItemIcon, ListItemText, Snackbar, Alert, } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import ReCAPTCHA from 'react-google-recaptcha';
// import { keyframes } from "@emotion/react";
import Header from "../components/Header";
import Footer from '../components/Footer';
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// const shakeAnimation = keyframes`
//   0% { transform: translateX(0); }
//   25% { transform: translateX(-5px); }
//   50% { transform: translateX(5px); }
//   75% { transform: translateX(-5px); }
//   100% { transform: translateX(0); }
// `;

// const bounceAnimation = keyframes`
//   0%, 100% { transform: translateY(0); }
//   50% { transform: translateY(-10px); }
// `;

const ContactPage = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('warning');
  // 'success' or 'warning'
  const [isloading, setIsLoading] = useState(false);


   const location = useLocation();
  
    useEffect(() => {
      if (location.path = "/contact") {
        document.title = "Informed ALF Contact us";
      } 
      // else {
      //   document.title = "Informed ALF"; // Set a default title for other routes
      // }
    }, [location.path]);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required.";
    }
    if (!formData.email.trim()) {
      errors.email = "Email Address is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (formData.phoneNumber && !/^\d{10}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone Number must be 10 digits.";
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required.";
    }
    return errors;
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!captchaValue) {
      setSnackbarMessage('Please complete the CAPTCHA to submit the form.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://app.dementiawhiz.com/service/api/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSnackbarMessage("We'll get back to you shortly.");
        setSnackbarSeverity('success');
        setFormData({ fullName: '', email: '', phoneNumber: '', message: '' }); // Reset form
      } else {
        setSnackbarMessage('Failed to send your message. Please try again.');
        setSnackbarSeverity('warning');
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage('An error occurred. Please try again.');
      setSnackbarSeverity('warning');
    } finally {
      setIsLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Header />

      <Container maxWidth="lg" sx={{ my: 1 }}>
        {/* Page Title */}
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4A4E69' }}>
            Contact Us
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            We’re here to support you. Whether you have a question, need assistance, or want more information, please feel free to reach out.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {/* Contact Information */}
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, backgroundColor: '#FBF7E9', borderRadius: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4A4E69', mb: 2 }}>
                Get in Touch
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Phone" secondary="941-279-0046" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Email" secondary="Info@DementiAbility.us" />
                </ListItem>


                {/* <ListItem> */}
                {/* <ListItemIcon> */}
                  {/* <LocationOnIcon color="primary" /> */}
                {/* </ListItemIcon> */}
                {/* <ListItemText primary="Address" secondary="123 Wall Street, New York, US, 10012" /> */}
                {/* </ListItem> */}
                {/* <ListItem> */}
                {/* <ListItemIcon> */}
                {/* <AccessTimeIcon color="primary" /> */}
                {/* </ListItemIcon> */}
                {/* <ListItemText primary="Office Hours" secondary="Mon - Fri: 9:00 AM - 6:00 PM" /> */}
                {/* </ListItem> */}
              </List>
            </Paper>



            <Paper
              elevation={3}
              sx={{
                mt: 2,
                p: 3,
                backgroundColor: "#FBF7E9",
                borderRadius: 2,
                textAlign: "center",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                // "&:hover": {
                //   transform: "scale(1.05)",
                //   boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                //   "& .shake-icon": {
                //     animation: `${shakeAnimation} 0.5s ease`,
                //   },
                // },
              }}
            >
              <Box className="shake-icon">
                <PhoneIcon fontSize="large" color="secondary" />
              </Box>
              <Typography
                variant="h6"
                sx={{ mt: 2, color: "#4A4E69", fontWeight: "bold" }}
              >
                Phone Support
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 1 }}
              >
                Speak directly with a support specialist for immediate assistance.
              </Typography>
            </Paper>

            <Paper
              elevation={3}
              sx={{
                mt: 2,
                p: 2,
                backgroundColor: "#FBF7E9",
                borderRadius: 2,
                textAlign: "center",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                // "&:hover": {
                //   transform: "scale(1.05)",
                //   boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                //   "& .bounce-icon": {
                //     // animation: `${bounceAnimation} 0.5s ease`,
                //   },
                // },
              }}
            >
              <Box className="bounce-icon">
                <EmailIcon fontSize="large" color="secondary" />
              </Box>
              <Typography
                variant="h6"
                sx={{ mt: 2, color: "#4A4E69", fontWeight: "bold" }}
              >
                Email Support
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 1, mb: 2.7 }}
              >
                Email us your questions, and we’ll reply within 24-48 hours.
              </Typography>
            </Paper>

          </Grid>

          {/* Contact Form */}
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: 4, backgroundColor: '#FBF7E9', borderRadius: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4A4E69', mb: 2 }}>
                Expressing Interest
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Fill out the form below, and a member of our team will respond within 24-48 hours.
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center' }}>
                <TextField
                  label="Full Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  error={!!formErrors.fullName}
                  helperText={formErrors.fullName}
                />
                <TextField
                  label="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData((prev) => ({ ...prev, [name]: value }));
                    if (formErrors[name]) {
                      setFormErrors((prev) => ({ ...prev, [name]: '' })); // Clear error on input
                    }
                  }}
                  onBlur={(e) => {
                    const { name, value } = e.target;
                    if (value.trim() && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                      setFormErrors((prev) => ({ ...prev, [name]: 'Invalid email address' }));
                    } else {
                      setFormErrors((prev) => ({ ...prev, [name]: '' })); // Clear error if valid
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  error={!!formErrors.email && formData.email.trim()} // Show error only if there's input
                  helperText={formData.email.trim() && formErrors.email} // Show helper text only if input exists
                />


                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits and restrict to 10 digits
                    if (/^\d*$/.test(value) && value.length <= 10) {
                      handleChange(e); // Update the value in the formData state
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  error={!!formErrors.phoneNumber}
                  helperText={formErrors.phoneNumber}
                />

                <TextField
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  required
                  error={!!formErrors.message}
                  helperText={formErrors.message}
                />
                <ReCAPTCHA
                  style={{ alignSelf: 'center' }}
                  sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR"
                  onChange={handleCaptchaChange}
                />
                <Button type="submit" variant="contained" sx={{
                  mt: 1, width: '55%', borderRadius: 2, alignSelf: 'center', bgcolor: "#b31c31",
                  "&&:hover": {
                    bgcolor: "#b31c31",
                    transform: "scale(1.05)",
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",

                  }
                }}
                >
                  {/* Submit */}
                  {isloading ? (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body4" marginRight={1}>
                        Submitting
                      </Typography>
                      <CircularProgress size={18} sx={{ color: '#FFFFFF' }} />
                    </Box>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>


        {/* Snackbar */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>

      <Footer />
    </div>
  );
};

export default ContactPage;
