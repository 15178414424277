import React from "react";
import {
  Grid,
  Typography,
  Link,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Alert,
  Snackbar,
  Stack, Card, CardContent, Box,
  TextField,
} from "@mui/material";
// import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../style/HomeStyles.css";
import Item from "@mui/material/Box";
import Header from "./Header";
import xyz2 from "../images/xyz2.jpg";
import Divider from "@mui/material/Divider";
import image from "../images/logo3.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AccessibilityNewOutlinedIcon from "@mui/icons-material/AccessibilityNewOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook'
// import firstimg3 from "../images/firstimg3.png";
// import secimg from "../images/secimg.png";
// import thirdimg from "../images/thirdimage.png";
// import fourthimg from "../images/fourthimage.png";
import CloseIcon from "@mui/icons-material/Close";
// import HTTPServices from "../services/HTTPServices";
import familyicon from "../images/familyicon.jpg";
import careicon from "../images/careicon.jpg";
import science2 from "../images/science2.png";
import science3 from "../images/science3.png";
import ReCAPTCHA from 'react-google-recaptcha';
import { color, styled } from "@mui/system";
import Swal from 'sweetalert2';
import { CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "20px", // Rectangle with rounded corners
    border: "2px solid #b31c31", // Add border for styling
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)", // Subtle shadow
    animation: "slideIn 0.4s ease-in-out", // Animation for smooth appearance
  },
  "@keyframes slideIn": {
    from: { transform: "translateY(50px)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#b31c31",
  color: "white",
  fontWeight: "bold",
  padding: "10px 20px",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#8f1625",
  },
}));


const cardData = [
  {
    title: "Supportive Care for All",
    icon: <FavoriteBorderIcon sx={{ height: 50, width: 50, color: "white" }} />,
    text: "Informed ALF celebrates every small win in your caregiving journey. We focus on positive reinforcement and encouragement, never on guilt."
  },
  {
    title: "Top Priority on Privacy",
    icon: <LockOutlinedIcon sx={{ height: 50, width: 50, color: "white" }} />,
    text: "Your data and journey are personal and private. We enforce the highest standards of security to protect your information."
  },
  {
    title: "Easy and Accessible",
    icon: <AccessibilityNewOutlinedIcon sx={{ height: 50, width: 50, color: "white" }} />,
    text: "Informed ALF is designed to be user-friendly for all ages and tech abilities. Our support team is always available to help you navigate the app."
  },
  {
    title: "Customer Satisfaction",
    icon: <ThumbUpOffAltOutlinedIcon sx={{ height: 50, width: 50, color: "white" }} />,
    text: "Try Informed ALF risk-free for 30 days. If you're not happy, we'll ensure a hassle-free refund."
  },
  {
    title: "Empowerment in Your Hands",
    icon: <BackHandOutlinedIcon sx={{ height: 50, width: 50, color: "white" }} />,
    text: "Adjust your caregiving plans and schedules as needed. Customize your goals and daily tasks to fit your unique needs."
  },
  {
    title: "Ongoing Care Excellence",
    icon: <MedicalServicesOutlinedIcon sx={{ height: 50, width: 50, color: "white" }} />,
    text: "Regular updates with the latest dementia care practices and research. 24/7 support to assist you whenever you need it."
  }
];

export default function Home() {
  // let http = new HTTPServices();

  const [open, openchange] = useState(false);
  const functionopenpopup = () => {
    openchange(true);
  };
  const closepopup = () => {
    openchange(false);
    clearForm();
    setErrors({
      CurrentName: '',
      CurrentEmail: '',
      CurrentRole: ''
    });
  };
  const initialFormState = {
    name: "",
    email: "",
    phone: "",
    // relationship: "",
    reason: "",
    notes: "",
  };
  // const navigate = useNavigate();
  const [CurrentRole, setCurrentRole] = React.useState("");
  const [CurrentName, setCurrentName] = React.useState("");
  const [CurrentEmail, setCurrentEmail] = React.useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openpop, setOpenpop] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // const [emailError, setEmailError] = useState("");
  // const [errors, setErrors] = useState({});
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [agreeError, setAgreeError] = useState('');

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    // relationship: "",
    reason: "",
    notes: "",
  });
  const [errors, setErrors] = useState({
    CurrentName: '',
    CurrentEmail: '',
    CurrentRole: ''
  });
  const validateForm = () => {
    const newErrors = {};

    // Full Name validation
    if (!formData.name || /[^A-Za-z\s]/.test(formData.name)) {
      newErrors.name = "Full Name is required and must contain only letters.";
    }

    // Email validation
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Phone validation (optional)
    // if (formData.phone && formData.phone.length !== 10) {
    //   newErrors.phone = "Phone number should be exactly 10 digits.";
    // }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (formData.phone.length < 10) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
    // Relationship validation
    // if (!formData.relationship) {
    //   newErrors.relationship = "Relationship to patient is required.";
    // }

    // Reason validation
    if (!formData.reason) {
      newErrors.reason = "Reason for interest is required.";
    }

    // Notes validation (optional)
    // No validation needed for notes in this case, as it's optional

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

const location = useLocation();

  useEffect(() => {
    if (location.path = "/") {
      document.title = "Informed ALF";
    } 
    // else {
    //   document.title = "Informed ALF"; // Set a default title for other routes
    // }
  }, [location.path]);

  const clearForm = () => {
    setCurrentName(''); // Reset name to empty string
    setCurrentEmail(''); // Reset email to empty string
    setCurrentRole(''); // Reset role to empty string
    setAgreeTerms(false); // Reset agreeTerms checkbox
    setNameError(null); // Clear name error
    setEmailError(null); // Clear email error
    setRoleError(null); // Clear role error
    setCaptchaVerified(false); // Reset captcha state if needed
    setPhoneError(null);
  };


  const regvalidation = () => {
    let valid = true; // This flag will track if all fields are valid

    // Reset previous error messages
    setErrors({
      CurrentName: '',
      CurrentEmail: '',
      CurrentRole: '',
      terms: false,

    });

    // Validate Name: it should not be empty and should not contain numbers
    if (!CurrentName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        CurrentName: 'Name is required'
      }));
      valid = false;
    } else if (/[^a-zA-Z ]/.test(CurrentName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        CurrentName: 'Name should only contain letters'
      }));
      valid = false;
    }

    // Validate Email: it should not be empty and should match the email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!CurrentEmail) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        CurrentEmail: 'Email is required'
      }));
      valid = false;
    } else if (!emailRegex.test(CurrentEmail)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        CurrentEmail: 'Enter a valid email address'
      }));
      valid = false;
    }

    // Validate Role: it should not be empty
    if (!CurrentRole) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        CurrentRole: 'Role is required'
      }));
      valid = false;
    }

    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCancel = () => {
    setOpenpop(false);
    setFormData(initialFormState);
    setErrors({
      CurrentName: '',
      CurrentEmail: '',
      CurrentRole: '',
      terms: false,

    });
  }
  // const handleSubmit = () => {
  //   // Submit logic (e.g., API call)
  //   console.log("Form submitted: ", formData);
  //   setOpenpop(false);
  //   alert("Thank you! Your interest has been submitted.");
  // };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!validateForm()) {
      setIsLoading(false);
      return;  // Stop the submission process if validation fails
    }

    const apiPayload = {
      fullName: formData.name,
      emailAddress: formData.email,
      phoneNumber: formData.phone,
      // relationToPatient: formData.relationship,
      interestReason: formData.reason,
      additionalNotes: formData.additionalNotes
    };
     if (!captchaVerified) {
      setSnackbarMessage('Please complete the CAPTCHA');
      setSnackbarOpen(true);
      setIsLoading(false);
      return;
    }
    try {
      const response = await fetch("https://app.dementiawhiz.com/service/api/express-interest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiPayload),
      });

      if (!response.ok) {
        throw new Error("Failed to submit your interest.");
      }
      if (response.status === 201) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Thank you for showing your interest in Informed Assisted Living Facility . We will get back to you soon.",
          showConfirmButton: false,
          timer: 3000,
        });
        closepopup();
        setCaptchaVerified(false); // Reset CAPTCHA status
      } else {
        // setSnackbarMessage("Failed to save data. Please try again.");
        // setSnackbarOpen(true);
      }
      const data = await response.json();
      console.log("API Response:", data);
      setOpenpop(false);
      // alert("Thank you! Your interest has been submitted.");
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error.message);
      // alert("Failed to submit your interest. Please try again later.");
      setSnackbarMessage("Failed to submit your interest. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
      setOpenpop(false)
    }
    setFormData(initialFormState);
  };

  const handleTerms = () => {
    navigator('/terms');
  }

  // const handleRoleChange = (event) => {
  //   setCurrentRole(event.target.value);
  // };
  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setCurrentRole(selectedRole);

    // Clear the error if a role is selected
    if (selectedRole) {
      setErrors((prevErrors) => ({ ...prevErrors, role: "" }));
    }
  };
  // const handleNameChange = (event) => {
  //   setCurrentName(event.target.value);
  // };
  const handleNameChange = (event) => {
    const { value } = event.target;
    setCurrentName(value);

    // Clear the error if the input is not empty
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
    }
  };
  // const handleEmailChange = (event) => {
  //   setCurrentEmail(event.target.value);
  // };
  const handleEmailChange = (event) => {
    setCurrentEmail(event.target.value);

    if (event.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Function to handle closing the modal
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // true if captcha completed
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const validatejoin = () => {
    let formErrors = {};
    if (!CurrentName) formErrors.name = 'Name is required';
    if (!CurrentEmail) formErrors.email = 'Email is required';
    if (!CurrentRole) formErrors.role = 'Role is required';
    if (!agreeTerms) formErrors.terms = 'Please agree to terms and conditions';
    console.log(formErrors);
    return formErrors;
  };
  const submitFormData = async () => {


    const formErrors = validatejoin();
    setErrors(formErrors);

    if (/[\d]/.test(CurrentName)) {
      formErrors.name = "Name cannot contain numbers.";
      setErrors(formErrors);
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation
    if (!emailRegex.test(CurrentEmail)) {
      formErrors.email = "Please enter a valid email address.";
      setErrors(formErrors);
    }
    if (Object.keys(formErrors).length > 0) return; // Do not submit if there are errors

    if (!agreeTerms) {
      formErrors.terms = 'Please agree to terms and conditions';
      setErrors(formErrors);
      console.log(formErrors);
      return
    }
    if (!captchaVerified) {
      setSnackbarMessage('Please complete the CAPTCHA');
      setSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    let data = {
      name: CurrentName,
      email: CurrentEmail,
      current_role: CurrentRole,
      agree: agreeTerms
    };

    try {
      setIsLoading(true);
      const response = await fetch('https://app.dementiawhiz.com/service/api/enquiries', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Thank you for showing your interest in informed Alf. We will get back to you soon.",
          showConfirmButton: false,
          timer: 3000,
        });
        closepopup();
        setIsLoading(false);
        setCaptchaVerified(false); // Reset CAPTCHA status
      } else {
        setSnackbarMessage("Failed to save data. Please try again.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };
  // const handleAgreeTermsChange = (e) => {
  //   setAgreeTerms(e.target.checked === true);
  // };
  const handleAgreeTermsChange = (e) => {
    setAgreeTerms(e.target.checked === true);
  };

  return (
    <div>
      {/* // FIRST-CONTAINER
    // FIRST-CONTAINER
    // FIRST-CONTAINER */}

      <Header />

      <Grid
        container
        spacing={2}
        sx={{
          bgcolor: "#FBF7E9",
          padding: {
            xs: "20px",
            sm: "30px",
            md: "20px",
            lg: "50px 0px 40px 90px",
          }, // Adjust padding for responsiveness
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={6.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" }, // Center-align text on small screens
            textAlign: { xs: "center", md: "left" }, // Center text on small screens
            fontWeight: "bolder",
            padding: { xs: "0px", sm: "0px", md: "100px", lg: "0px" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "900",
              fontFamily: "poppins",
              color: "#125782",
              fontSize: { xs: "36px", sm: "45px", md: "65px" }, // Responsive font size
              lineHeight: { xs: "44px", sm: "55px", md: "70px" }, // Responsive line height
            }}
          >
            At Your Fingertips Informed Care Coordination doesn't have to feel empty
          </Typography>

          <Typography
            sx={{
              marginTop: "20px",
              fontSize: { xs: "16px", sm: "20px", md: "23px" }, // Responsive font size
              fontWeight: "bold",
            }}
          >
            Informed ALF app will fill in the gaps of effective resident care by providing needed information at your fingertips with one sign in
          </Typography>

          <Button
            onClick={() => setOpenpop(true)}
            sx={{
              marginTop: "20px",
              width: { xs: "100%", sm: "auto", md: "80%" },
              maxWidth: "411px",
              height: "50px",
              bgcolor: "#b31c31",
              alignItems: "center",
              color: "white",
              "&:hover": {
                bgcolor: "#b31c31", // Background color on hover
                color: "white", // Text color on hover
              },
              fontSize: { xs: "16px", sm: "18px", md: "18px" }, // Responsive font size
              fontWeight: "700",
              fontFamily: "poppins",
              borderRadius: "10px"
            }}
          >
            Express Interest
          </Button>

          {/* Express Interest Popup */}
          <StyledDialog
            open={openpop}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setOpenpop(false); // Only close if the reason is not backdrop click
              }
            }}
            fullWidth
            maxWidth="sm"
            BackdropProps={{ invisible: false }} // Ensures backdrop is visible
          >
            <DialogTitle sx={{ textAlign: "center", bgcolor: "#f7f7f7", }}>
              <Typography variant="h5" fontWeight="bold" color="#125782">
                We're Here to Help
              </Typography>
              <Typography variant="body2" color="textSecondary" mt={1}>
                Please provide your details, and we’ll get in touch with you shortly.
              </Typography>
            </DialogTitle>

            <DialogContent sx={{ padding: "20px 30px", bgcolor: "#fbf7e9", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box component="form" noValidate>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  sx={{ borderRadius: "5px" }}
                  inputProps={{
                    pattern: "[A-Za-z ]*", // Accepts only alphabets and spaces
                    title: "Only letters and spaces are allowed", // Optional message
                  }}
                  onInput={(e) => {
                    // Filter out numbers, but allow spaces
                    e.target.value = e.target.value.replace(/[^A-Za-z ]/g, ''); // Allow spaces
                  }}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <TextField
                  fullWidth
                  required
                  label="Phone Number"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  // onChange={handleChange}
                  onChange={(e) => {
                    // Remove any invalid characters (allow only numbers)
                    const value = e.target.value.replace(/[^0-9]/g, "");

                    // Limit the input to 10 digits
                    if (value.length <= 10) {
                      handleChange({ target: { name: "phone", value } });
                    }
                  }}
                  onKeyDown={(e) => {
                    // Prevent invalid key presses (e.g., letters) but allow navigation and editing keys
                    if (
                      !/[0-9]/.test(e.key) && // Allow only digits
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "Tab"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
                {/* <TextField
                  fullWidth
                  label="Relationship to Patient"
                  name="relationship"
                  value={formData.relationship}
                  // onChange={handleChange}
                  onChange={(e) => {
                    // Remove any numeric characters
                    const value = e.target.value.replace(/[0-9]/g, '');
                    handleChange({
                      target: { name: "relationship", value },
                    });
                  }}
                  variant="outlined"
                  margin="normal"
                  error={!!errors.relationship}
                  helperText={errors.relationship}
                /> */}
                <TextField
                  required
                  fullWidth
                  label="Reason for Interest"
                  name="reason"
                  value={formData.reason}
                  // onChange={handleChange}
                  onChange={(e) => {
                    // Remove any numeric characters
                    const value = e.target.value.replace(/[0-9]/g, '');
                    handleChange({
                      target: { name: "reason", value },
                    });
                  }}
                  variant="outlined"
                  margin="normal"
                  error={!!errors.reason}
                  helperText={errors.reason}
                />
                <TextField
                  fullWidth
                  label="Additional Notes (Optional)"
                  name="additionalNotes"
                  value={formData.additionalNotes}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={2}
                />
              </Box>
              <ReCAPTCHA
                backgroundColor="#000"
                style={{ alignSelf: "center" }}
                sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR"
                onChange={handleCaptchaChange}
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", bgcolor: "#f7f7f7", padding: "10px 20px" }}>
              <Button
                onClick={handleCancel}
                sx={{
                  color: "#b31c31",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
              >
                Cancel
              </Button>
              <StyledButton onClick={handleSubmit}>
                {/* Submit */}
                {isloading ? (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body4" marginRight={1}>
                      Submitting
                    </Typography>
                    <CircularProgress size={18} sx={{ color: '#FFFFFF' }} />
                  </Box>
                ) : (
                  "Submit"
                )}
              </StyledButton>
            </DialogActions>
          </StyledDialog>


          {/* User Registration Popup */}
          {/* <Dialog
            bgcolor="#FBF7E9"
            open={open}
            onClose={closepopup}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              Join Now
              <IconButton onClick={closepopup} style={{ float: "right" }}>
                <CloseIcon color="#FFE636" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} margin={2}>
                <TextField
                  required
                  variant="outlined"
                  label="Name"
                  name="Name"
                  value={CurrentName}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[0-9]/g, "");
                    handleNameChange({ target: { name: "Name", value } });
                  }}
                  error={!!errors.CurrentName}
                  helperText={errors.CurrentName}
                />
                <TextField
                  required
                  variant="outlined"
                  label="Email"
                  name="Email"
                  value={CurrentEmail}
                  onChange={handleEmailChange}
                  error={!!errors.CurrentEmail}
                  helperText={errors.CurrentEmail}
                />
                <FormControl required sx={{ m: 2, minWidth: 150 }}>
                  <InputLabel id="demo-simple-select-required-label">
                    Current-Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    name="Currentrole"
                    id="demo-simple-select-required"
                    value={CurrentRole}
                    label="CurrentRole"
                    onChange={handleRoleChange}
                    error={!!errors.CurrentRole}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Caregiver">Caregiver</MenuItem>
                    <MenuItem value="Family Member">Family Member</MenuItem>
                    <MenuItem value="Patient">Patient</MenuItem>
                    <MenuItem value="Assisted Living Facility">
                      Assisted Living Facility
                    </MenuItem>
                    <MenuItem value="Activity Taker">Supporter</MenuItem>
                  </Select>
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeTerms}
                      onChange={handleAgreeTermsChange}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">Agree to </Typography>
                      <Button
                        onClick={() => navigate("/terms")}
                        sx={{
                          textTransform: "none",
                          padding: 0,
                          marginLeft: "4px",
                          color: "#125782",
                          fontSize: "14px",
                          fontWeight: "bold",
                          "&:hover": {
                            textDecoration: "underline",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        Terms & Conditions
                      </Button>
                    </Box>
                  }
                />
                <ReCAPTCHA
                  style={{ alignSelf: "center" }}
                  sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR"
                  onChange={handleCaptchaChange}
                />
                <Button
                  onClick={submitFormData}
                  sx={{
                    borderRadius: "13px",
                    bgcolor: "#b31c31",
                    color: "white",
                    "&:hover": {
                      bgcolor: "#b31c31",
                      color: "white",
                    },
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "poppins",
                  }}
                >
                  {isloading ? (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body4" marginRight={1}>
                        Submitting
                      </Typography>
                      <CircularProgress size={18} sx={{ color: "#FFFFFF" }} />
                    </Box>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Stack>
            </DialogContent>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={captchaVerified ? "success" : "warning"}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Dialog> */}





          <Dialog
            open={open}
            onClose={closepopup}
            fullWidth
            maxWidth="sm"
            alignItems="center"
          >
            <DialogTitle sx={{ bgcolor: '#FBF7E9', display: 'flex', justifyContent: 'space-between', alignItems: 'center', pl: 5, pt: 3 }} variant="h5">
              <Typography sx={{ bgcolor: '#FBF7E9', flex: 1, textAlign: 'center', fontSize: '25px', fontWeight: '600', fontFamily: 'Poppins', color: '#125782' }}>JOIN NOW</Typography>
              <IconButton onClick={closepopup} sx={{ ml: 2 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent sx={{ bgcolor: '#FBF7E9' }}>
              <Stack spacing={2} margin={2}>
                <TextField
                  type="text"
                  required
                  variant="outlined"
                  label="Name"
                  name="Name"
                  value={CurrentName}
                  onChange={handleNameChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />


                <TextField
                  required
                  variant="outlined"
                  label="Email"
                  name="Email"
                  value={CurrentEmail}
                  onChange={handleEmailChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <FormControl required error={!!errors.role} sx={{ m: 2, minWidth: 150 }}>
                  <InputLabel id="demo-simple-select-required-label">Current-Role </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    name="Currentrole"
                    id="demo-simple-select-required"
                    value={CurrentRole}
                    label="CurrentRole"
                    onChange={handleRoleChange}
                  >
                    {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                    <MenuItem value="Assisted Living Facility">Assisted Living Facility</MenuItem>
                    <MenuItem value="Caregiver">Caregiver</MenuItem>
                    <MenuItem value="Family Member">Family Member</MenuItem>
                    <MenuItem value="Patient">Patient</MenuItem>
                  </Select>
                  {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
                  <FormControl required error={!!errors.agree} component="fieldset" variant="standard">

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={agreeTerms}
                          onChange={handleAgreeTermsChange}
                        />
                      }
                      label={
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">Agree to </Typography>
                          <Button
                            // onClick={() => navigate("/Terms")}
                            onClick={() => window.open("/Terms", "_blank")}
                            sx={{
                              textTransform: "none",
                              padding: 0,
                              marginLeft: "4px",
                              color: "#125782",
                              fontSize: "14px",
                              fontWeight: "bold",
                              "&:hover": {
                                textDecoration: "underline",
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            Terms & Conditions
                          </Button>
                        </Box>
                      }
                    />
                  </FormControl>  {/* <FormHelperText>Required</FormHelperText> */}
                  {errors.terms && (
                    <FormHelperText variant="caption" sx={{ color: "red" }}>{errors.terms}</FormHelperText>
                  )}
                </FormControl>

                <ReCAPTCHA
                  style={{ alignSelf: 'center' }}
                  sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR" // Replace with your ReCAPTCHA site key
                  onChange={handleCaptchaChange}
                />
                <Button
                  onClick={submitFormData}
                  mt={2}
                  sx={{
                    borderRadius: "13px",
                    bgcolor: "#b31c31",
                    color: "white",
                    '&:hover': {
                      bgcolor: '#a01729',
                    },
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "poppins",
                  }}
                >
                  {/* Submit */}
                  {isloading ? (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body4" marginRight={1}>
                        Submitting
                      </Typography>
                      <CircularProgress size={18} sx={{ color: '#FFFFFF' }} />
                    </Box>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Stack>
            </DialogContent>
            {/* <DialogActions></DialogActions> */}
          </Dialog>


        </Grid>

        <Grid item xs={12} md={12} lg={5} sx={{ margin: "60px 0px 30px 10px" }}>
          <img
            src="https://img.freepik.com/premium-photo/nurse-training-senior-patient-clinic_1048944-8038449.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
            // src={firstimg3}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "560px",

              height: "400px",
              borderRadius: "25px",

            }}
          />
        </Grid>
      </Grid >

      {/* // second-container
// second-container
// second-container
// second-container */}

      < Grid
        container
        sx={{
          height: "auto", // Adjusted for flexibility
          bgcolor: "#125782",
          padding: { xs: "20px", sm: "30px", md: "50px 25px" }, // Responsive padding
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }
        }
      >
        <Grid
          item
          xs={12}
          sx={{
            color: "#ffffff",
            fontSize: { xs: "12px", sm: "15px" }, // Responsive font size
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <Item>THE SCIENCE</Item>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: { xs: "10px", sm: "2px" }, // Responsive margin
            color: "#ffffff",
            fontWeight: "800",
            fontSize: { xs: "24px", sm: "36px", md: "46px" }, // Responsive font size
            textAlign: "center",
          }}
        >
          <Item>Powered by experience, backed by commitment of care.</Item>
        </Grid>

        <Grid
          item
          xs={12}
          lg={12}
        // sx={{
        //   color: "#ffffff",
        //   fontSize: { xs: "14px", sm: "18px" }, // Responsive font size
        //   margin: { xs: "0px 20px", sm: "0px 159px" }, // Responsive margin
        //   textAlign: "center",
        //   padding: { xs: "10px 0", sm: "10px 10px 0px 170px" }, // Responsive padding
        // }}
        >
          <Typography
            // xs={12}

            sx={{
              color: "#ffffff",
              fontSize: { xs: "14px", sm: "18px" }, // Responsive font size
              margin: { xs: "0px 20px", sm: "0px 159px" }, // Responsive margin
              textAlign: "center",
              padding: {
                xs: "10px 0",
                sm: "10px 10px 0px 10px",
                lg: "10px 10px 0px 10px",
              }, // Responsive padding
            }}
          >
            A majority of caregivers (70%) report that coordination of care is
            stressful.
          </Typography>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: { xs: "20px", sm: "30px" },
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <img
              src={science2}
              alt="logo"
              style={{ maxWidth: "100%", height: "auto" }} // Responsive image
            // sx={{}}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={5} sx={{
            display: "flex",
            justifyContent: "center",
            margin: { xs: "0px 0px 0px 14px", sm: "0px 0px 0px 0px", md: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" }
          }}>
            <img
              src={science3}
              alt="logo"
              style={{ maxWidth: "100%", height: "auto", margin: "0px" }} // Responsive image
            // sx={{objectFit: "contain"}}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          lg={5}
          sx={{ textAlign: "center", marginTop: { xs: "20px", sm: "30px" } }}
        >
          <Item>
            <Button
              onClick={() => setOpenpop(true)}
              sx={{
                bgcolor: "#b31c31",
                color: "white",
                "&:hover": {
                  bgcolor: "#b31c31", // Background color on hover
                  color: "white", // Text color on hover
                },
                height: "50px",
                width: { xs: "370px", sm: "auto", lg: "400px" },
                maxWidth: { xs: "315px", sm: "auto", lg: "410px" },
                fontSize: { xs: "16px", sm: "20px", md: "18px" }, // Responsive font size
                fontWeight: "700",
                fontFamily: "poppins",
                // paddingTop: "8px",
                borderRadius: "10px"
              }}
            >
              Express Interest
            </Button>
          </Item>
        </Grid>
      </Grid >

      {/* fifth-Container  */}
      {/* fifth-Container 
      fifth-Container 
      fifth-Container 
      fifth-Container 
      fifth-Container 
      fifth-Container  */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: "#FBF7E9",
          padding: "50px 27px 0px 27px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            bgcolor: "#FBF7E9",
            height: "22px",
            color: "#000000",
            fontSize: "15px",
            fontWeight: "700",
            margin: "0px 0px 8px 0px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              bgcolor: "FBF7E9",
              height: "22px",
              color: "#000000",
              fontSize: { xs: "18px", sm: "15px", md: "15px", lg: "15px" },
              fontWeight: { xs: "500", sm: "600", md: "700", lg: "700" },
              margin: "0px 0px 8px 0px",
              textAlign: "center",
            }}
          >
            The Benefits
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          sx={{
            bgcolor: "#FBF7E9",
            height: "52px",
            color: "#000000",
            fontSize: "46px",
            fontWeight: "bolder",
            margin: "0px 0px 20px 0px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              bgcolor: "#FBF7E9",
              height: { xs: "195px", sm: "52px", md: "52px", lg: "52px" },
              color: "#000000",
              fontSize: "46px",
              fontWeight: "bolder",
              margin: {
                xs: "0px 0px 330px 0px",
                sm: "0px 0px 230px 0px",
                md: "0px 0px 120px 0px",
                lg: "0px 0px 150px 0px",
              },
              textAlign: "center",
              lineHeight: { xs: "47px", sm: "52px", md: "52px", lg: "52px" },
            }}
          >
            The most supportive version of Informed Care Coordination for each person living in assisted living
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={9}
          sx={{
            bgcolor: "#FBF7E9",
            color: "#000000",
            height: "52px",
            fontSize: "18px",
            margin: {
              xs: "0px 0px 50px 0px",
              sm: "0px 0px 50px 0px",
              md: "0px 0px 50px 0px",
              lg: "0px 0px 30px 0px",
            },
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              bgcolor: "#FBF7E9",
              color: "#000000",
              height: "52px",
              fontSize: "18px",
              margin: {
                xs: "0px 0px 50px 0px",
                sm: "0px 0px 50px 0px",
                md: "0px 0px 50px 0px",
                lg: "0px 0px 0px 0px",
              },
              textAlign: "center",
            }}
          >
            Coordination of care is stressful enough. Informed ALF captures the
            most detail in one app in order to improve health outcomes for our
            most vulnerable senior population.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={11.5}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 50px 0px",
              sm: "0px 0px 50px 0px",
              md: "0px 0px 50px 0px",
              lg: "0px 0px 0px 100px",
            },
            margin: "30px 0px 0px 0px",
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            alignItems: "left",
            gap: "20px",
          }}
        >
          {/* Benefits Cards  */}
          {[
            {
              image: xyz2,
              title: "For Patients Enhanced Care with No Pressure:",
              benefits: [
                "Provide better support in managing their lived experience",
                "Improve personal hygiene outcomes",
                "Reduce behavioral instances of confusion and anxiety",
              ],
            },
            {
              image: familyicon,
              title: "For Family Members Improved Peace of Mind:",
              benefits: [
                "Better success rates for more accomplished caregiving",
                "Improvements in loved one's mental health",
                "Experience reduced caregiving stress knowing you made the correct decision",
              ],
            },
            {
              image: careicon,
              title: "For Caregivers Elevated Care Efficiency and Focus:",
              benefits: [
                "Confidently providing improved care coordination",
                "Enhanced productivity in care routines",
                "Shared resources specific to person living with Dementia care",
              ],
            },
          ].map((card, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3.7}
              key={index}
              sx={{
                bgcolor: "white",
                padding: "30px 20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 1,
                borderRadius: 1,
              }}
            >
              <img
                src={card.image}
                alt="benefit"
                style={{ width: "97px", height: "67px", marginBottom: "10px" }}
              />
              <Typography
                sx={{
                  fontSize: "20px",
                  margin: "0px 0px 5px 0px",
                  fontWeight: "800",
                }}
              >
                {card.title}
              </Typography>

              <Divider
                sx={{
                  width: "100%",
                  height: "10px",
                  margin: "8px 0px 14px 0px",
                }}
              />

              <Typography
                sx={{
                  fontSize: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  textAlign: "left",
                }}
              >
                {card.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            justifyContent: "flex-end",
            alignItems: "flex-start",
            margin: "0px 0px 50px 0px",
          }}
        >
          <Button
            onClick={functionopenpopup}
            sx={{
              bgcolor: "#b31c31",
              fontFamily: "poppins",
              color: "white",
              "&:hover": {
                bgcolor: "#b31c31", // Background color on hover
                color: "white", // Text color on hover
              },
              fontSize: { xs: "14px", sm: "14.5px", md: "18px", lg: "18px" },
              fontWeight: "700",
              height: "50px",
              padding: "10px 30px",
              textAlign: "center",
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "30px 0px 0px 0px",
              },
              borderRadius: "10px"
            }}
          >
            Join Informed ALF Today
          </Button>
        </Grid>
      </Grid>

      {/* sixth container  */}
      {/* sixth container
      sixth container
      sixth container
      sixth container
      sixth container
      sixth container */}

      <Grid
        container
        spacing={2}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          margin: "50px 0px",
          padding: { xs: "0px 0px", md: "0px 130px" },
        }}
      >
        <Grid
          container
          item
          xs={11}
          lg={12}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography
            sx={{ fontWeight: "550", fontSize: { xs: "14px", md: "15px" } }}
          >
            THE FEATURES
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={11}
          lg={12}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontWeight: "900",
              fontSize: { xs: "28px", sm: "36px", md: "46px" },
              textAlign: "center",
            }}
          >
            Experience the Improved Shift in Care
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={11}
          lg={12}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: { xs: "14px", md: "18px" },
              textAlign: "center",
              margin: { xs: "8px 16px", md: "8px 128.50px" },
            }}
          >
            Informed ALF safeguards the person's preferences, legal paperwork,
            medical, emotional, and physical history, personalized tips and
            techniques for informed personal care, and so much more. This app
            will revolutionize care for those unable to recall in everyday or
            emergency situations. Informed ALF also provides educational
            components with simple searchable terms for access to specific
            hand-picked videos and/or specific easy reading materials.
          </Typography>
        </Grid>

        <Divider sx={{ width: "100%", height: "10px", margin: "30px 0px" }} />

        <Grid container item spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: "#B05DF1",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "25px 20px",
              }}
            >
              COMMUNITY
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "24px", md: "32px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "0px 20px",
              }}
            >
              Information
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              You could have easy access to Medical Information, Admission
              Paperwork, Demographics, Family Information and Contacts, Personal
              History, Preferences, Allergies, Personalized Activities of Daily
              Living, A Toolbox of Educational Tips and Techniques, and Videos,
              and Articles
            </Typography>

            <Button
              onClick={() => setOpenpop(true)}
              sx={{
                width: { xs: "100%", sm: "auto", md: "80%" },
                // maxWidth: "283px",
                height: "50px",
                bgcolor: "#b31c31",
                color: "white",
                "&:hover": {
                  bgcolor: "#b31c31", // Background color on hover
                  color: "white", // Text color on hover
                },
                fontSize: "18px",
                fontWeight: "600",
                marginTop: "20px",
                borderRadius: "10px"
              }}
            >
              Express Interest
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              // textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              padding: { xs: "0px 20px 0px 20px", md: "0px 20px 0px 20px", },
              margin: { xs: "15px 0px 0px 0px", md: "15px 0px 0px 0px", lg: "20px 0px 0px 0px" },
            }}
          >
            <img
              // src={secimg}
              src="https://img.freepik.com/free-photo/blinked-smiling-young-female-doctor-wearing-medical-robe-with-stethoscope-sitting-desk-work-computer-with-medical-tools-holding-folder-showing-you-gesture-pink-wall-with-copy-space_141793-23482.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "336px",
                height: "300px",
                borderRadius: "30px",
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", height: "10px", margin: "30px 0px" }} />

        <Grid container item spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: "#B05DF1",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "25px 20px",
              }}
            >
              TRACKING
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "24px", md: "32px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "0px 20px",
              }}
            >
              One Stop App
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              Don’t always have the client information you would need in one
              place with built in search capabilities? It is in there!
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              Feeling as though you could use a bit more education, training, or
              hints on what to do in certain situations? It is in there!
            </Typography>
            <Button
              onClick={() => setOpenpop(true)}
              sx={{
                width: { xs: "100%", sm: "auto", md: "80%" },

                height: "50px",
                bgcolor: "#b31c31",
                color: "white",
                "&:hover": {
                  bgcolor: "#b31c31", // Background color on hover
                  color: "white", // Text color on hover
                },
                fontSize: "18px",
                fontWeight: "600",
                marginTop: "20px",
                borderRadius: "10px"
              }}
            >
              Express Interest
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: "center",
              padding: { xs: "0px 20px 0px 20px", md: "0px 20px 0px 20px", },
              margin: { xs: "15px 0px 0px 0px", md: "15px 0px 0px 0px", lg: "27px 0px 0px 0px" },

            }}
          >
            <img
              // src={thirdimg}
              src="https://img.freepik.com/free-photo/african-american-customer-putting-phone-pos-terminal-paying-stylish-clothes-boutique-after-discussing-items-fabric-with-employee-woman-standing-retail-store-counter-buying-casual-wear_482257-67409.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "336px",
                // height: "auto",
                height: "300px",
                borderRadius: "30px",
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", height: "10px", margin: "30px 0px" }} />

        <Grid container item spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: "#B05DF1",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "25px 20px",
              }}
            >
              COACHING
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "24px", md: "32px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "0px 20px",
              }}
            >
              Convenience and Confidence
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              Know that you are getting the most current information and
              details.
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              Securing the private and helpful details to build trusting
              relationships of care.
            </Typography>
            <Button
              onClick={() => setOpenpop(true)}
              sx={{
                width: { xs: "100%", sm: "auto", md: "80%" },
                height: "50px",
                bgcolor: "#b31c31",
                color: "white",
                "&:hover": {
                  bgcolor: "#b31c31", // Background color on hover
                  color: "white", // Text color on hover
                },
                fontSize: "18px",
                fontWeight: "600",
                marginTop: "20px",
                borderRadius: "10px"
              }}
            >
              Express Interest
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: "center",
              padding: { xs: "0px 20px 0px 20px", md: "0px 20px 0px 20px" },
              margin: { xs: "20px 0px 0px 20px", md: "40px 0px 0px 0px", lg: "20px 0px 0px 0px" },

            }}
          >
            <img
              // src={fourthimg}
              src="https://img.freepik.com/premium-photo/doctor-chief-nurse-medical-department-exchange-handshake-hallway_597987-7400.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "336px",
                height: "300px",
                // height: "auto",
                borderRadius: "30px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* 
seventh container 
seventh container 
seventh container 
seventh container  
*/}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: "#B05DF1",
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: "40px 20px", md: "60px 40px", lg: "80px 80px" },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "#B05DF1",
            fontSize: "15px",
            color: "white",
            fontWeight: "700",
            textAlign: "center",
            mb: 2,
          }}
        >
          WE MEAN IT
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "#B05DF1",
            textAlign: "center",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "32px", md: "38px", lg: "46px" },
              fontWeight: "800",
              color: "white",
            }}
          >
            Our commitment to you.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={10}
          lg={8}
          sx={{
            bgcolor: "#B05DF1",
            textAlign: "center",
            mb: 4,
            mt: 2.5,
            mx: "auto",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "900", color: "white" }}
          >
            We understand the deeply personal journey of caring for someone that is living in assisted living and want you to feel confident that Informed ALF will cover all the bases needed to support positive service successes
          </Typography>
        </Grid>




        <Grid
          container
          spacing={1} // Space between grid items
          sx={{
            bgcolor: "#B05DF1",
            justifyContent: "center",
            alignItems: "stretch", // Ensure items stretch equally to fill the space
            padding: 3,
          }}
        >
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "#B05DF1",
                  color: "white",
                  borderRadius: 2,
                  padding: 3, // Added consistent padding for all cards
                  boxSizing: "border-box", // Ensures padding doesn't affect layout
                  textAlign: "center",
                  height: "100%", // Ensures the card grows to fill the container
                  boxShadow: "none", // Removes the shadow
                  border: "none", // Removes the border
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center", // Centers the content vertically
                    alignItems: "center",
                    padding: "16px", // Same padding inside the card
                    flexGrow: 1, // Ensures content grows evenly
                    height: "100%", // Ensures content stretches to match the card height
                    boxSizing: "border-box",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
                    {card.icon}
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 1 }}>
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
                      lineHeight: 1.5,
                      textAlign: "center",
                      flexGrow: 1, // Ensures text container grows to fill available space
                      overflow: "hidden", // Prevents overflow
                      textOverflow: "ellipsis", // Adds ellipsis for long text
                      display: "block",
                    }}
                  >
                    {card.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>




      </Grid>

      {/* footer */}
      {/* footer */}
      {/* footer */}
      {/* footer */}


      <Grid
        container
        sx={{
          bgcolor: "white",
          margin: "0px",
          padding: {
            xs: "20px 15px",
            sm: "30px 20px",
            md: "50px 27px",
            lg: "50px 27px 20px 27px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "white",
            margin: "0px",
            padding: {
              xs: "20px 15px",
              sm: "30px 20px",
              md: "40px 50px 80px 50px",
              lg: "0px 0px 20px 50px",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "white",
              color: "green",
              margin: "0px 0px 40px 0px",
              height: "60px",
              width: "290px",
              display: "flex",
              textAlign: "left",
            }}
          >
            <img
              src={image}
              alt="logo"
              style={{ width: "70px", height: "60px" }}
            />
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                fontWeight: "bold",
                color: "#125782",
                padding: "10px 0px 0px 5px",
              }}
            >
              Informed ALF
            </Typography>
          </Grid>

          <Grid
            container
            spacing={4}
            sx={{
              bgcolor: "white",
              color: "black",
              padding: "0px",
              textAlign: "left",
            }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={2}
              sx={{
                bgcolor: "white",
                margin: { xs: "0px 0px", md: "0px 100px 0px 0px" },
              }}
            >
              <Link
                // href="/about"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: 600,
                  // make it responsive fontsize
                  // fontSize: "25px",
                  fontSize: { xs: "15px", md: "20px", lg: "20px", xl: "23px" },

                  height: "32px",
                }}
              >
                Explore
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Link
                href="/"
                sx={{
                  margin: {
                    xs: "0px 0px px 0px",
                    sm: "0px 0px px 0px",
                    md: "0px 0px px 0px",
                    lg: "0px 0px px 0px",
                    xl: "0px 0px px 0px"
                  },
                  textDecoration: "none",
                  color: "black",
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" },
                  height: "32px",
                }}
              >
                Home
              </Link>
              <br />
              <Link
                href="/about"
                sx={{
                  margin: {
                    xs: "0px 0px 4px 0px",
                    sm: "0px 0px 4px 0px",
                    md: "0px 0px 4px 0px",
                    lg: "0px 0px 4px 0px",
                    xl: "0px 0px 4px 0px"
                  },
                  textDecoration: "none",
                  color: "black",
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" },
                  height: "32px",
                }}
              >
                About Us
              </Link>
              <br />
              <Link
                href="/Help"
                sx={{
                  margin: {
                    xs: "0px 0px 4px 0px",
                    sm: "0px 0px 4px 0px",
                    md: "0px 0px 4px 0px",
                    lg: "0px 0px 4px 0px",
                    xl: "0px 0px 4px 0px"
                  },
                  textDecoration: "none",
                  color: "black",
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" },
                  height: "32px",
                }}
              >
                Help & Support
              </Link>
              <br />
              <Link
                href="/contact"
                sx={{
                  margin: {
                    xs: "0px 0px 4px 0px",
                    sm: "0px 0px 4px 0px",
                    md: "0px 0px 4px 0px",
                    lg: "0px 0px 4px 0px",
                    xl: "0px 0px 4px 0px"
                  },
                  textDecoration: "none",
                  color: "black",
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" },
                }}
              >
                Contact Us
              </Link>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={2}
              sx={{
                bgcolor: "white",
                margin: { xs: "0px", md: "0px 100px 0px 0px" },
                textAlign: "left",
              }}
            >
              <Typography
                // href="#"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: 600,
                  fontSize: { xs: "15px", md: "20px", lg: "20px", xl: "23px" },
                }}
              >
                Get in Touch
              </Typography>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Link
                href="https://www.instagram.com/informedalf?igsh=MWpuYXFvYmd6YW5zZg=="
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "inline-flex", // Ensure the link contents align inline
                  alignItems: "center", // Center icon and text vertically
                  gap: "8px", // Add space between icon and text
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, // Text size
                }}
              >
                <InstagramIcon sx={{ fontSize: { xs: "20px", md: "24px" }, color: "black" }} />
                <span>Instagram</span>
              </Link>

              <br />
              <Link
                href="https://x.com/InformedAlf"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "inline-flex", // Ensure the link contents align inline
                  alignItems: "center", // Center icon and text vertically
                  gap: "8px", // Add space between icon and text
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, // Text size
                }}
              >
                <TwitterIcon sx={{ fontSize: { xs: "20px", md: "24px" }, color: "black" }} />
                <span>X</span>
              </Link>

              <br />
              <Link
                href="https://www.youtube.com/channel/UCsB14X9mnpALoHPE6JqxSwA"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "inline-flex", // Ensure the link contents align inline
                  alignItems: "center", // Center icon and text vertically
                  gap: "8px", // Add space between icon and text
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, // Text size
                }}
              >
                <YouTubeIcon sx={{ fontSize: { xs: "20px", md: "24px" }, color: "black" }} />
                <span>Youtube</span>
              </Link>

              <br />
              <Link
                href="https://www.facebook.com/profile.php?id=61568607891886&sk=about"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "inline-flex", // Ensure the link contents align inline
                  alignItems: "center", // Center icon and text vertically
                  gap: "8px", // Add space between icon and text
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, // Text size
                }}
              >
                <FacebookIcon sx={{ fontSize: { xs: "20px", md: "24px" }, color: "black" }} />
                <span>Facebook</span>
              </Link>

              <br />

            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{
                bgcolor: "white",
                color: "black",
                margin: "0px",
                textAlign: "left",
                padding: { xs: "0px 0px 0px 0px", md: "0px 0px 0px 0px" },
              }}
            >
              <Link
                // href="#"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "600",
                  fontSize: { xs: "15px", md: "20px", lg: "20px", xl: "23px" },
                }}
              >
                About Informed ALF
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Typography sx={{ color: "black", fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, }}>
                Informed ALF was created to provide ease of access to important and personal information about each person living in assisted living in order to provide individualized and informed care coordination
              </Typography>
              <br />
              <Typography sx={{ color: "black", fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, }}>
                Informed ALF safeguards the person's preferences, legal
                paperwork, medical, emotional, and physical history,
                personalized tips and techniques for informed personal care, and
                so much more. This app will revolutionize care for those unable
                to recall in everyday or emergency situations.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "white",
              color: "black",
              margin: "40px 0px 40px 0px",
              textAlign: "left",
            }}
          >
            <Divider sx={{ margin: "50px 0px 20px 0px", color: "black" }} />

            <Grid
              container
              sx={{
                bgcolor: "white",
                display: "flex",
                margin: "30px 0px 0px 0px",
              }}
            >
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ bgcolor: "white", display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#919191",
                    fontSize: { xs: "12px", md: "14px" },
                    margin: "0px",
                  }}
                >
                  Copyright 2024 Informed ALF. All rights reserved.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                lg={8}
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: { xs: "center", lg: "right" },
                  fontSize: { xs: "12px", md: "14px" },
                  margin: "0px",
                }}
                style={{ color: "#919191" }}
              >
                <Link
                  href="/policy"
                  style={{ color: "#919191", marginRight: "10px" }}
                >
                  Privacy Policy
                </Link>
                |
                <Link href="/Terms" style={{ color: "#919191", margin: "0px 10px" }}>
                  Terms & Conditions
                </Link>

                {/* <Link onClick={handleOpenDialog} style={{ color: "#919191", marginLeft: "10px", cursor: "pointer" }}>
                  Cookie Policy
                </Link> */}

                {/* Cookie Policy Dialog */}
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  maxWidth="sm"
                  fullWidth
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle>Cookie Policy</DialogTitle>
                  <Divider />

                  <DialogContent sx={{ minHeight: "350px" }}>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      This website uses cookies to enhance user experience, personalize content,
                      and analyze our traffic. By using our site, you agree to our use of cookies.
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      Cookies are small data files stored on your device. They help us improve
                      our services and provide a more personalized experience. You may disable cookies
                      through your browser settings, but this may affect your experience on our site.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      At Informed ALF, we understand the importance of privacy and safeguarding sensitive
                      information, especially for individuals with dementia and their caregivers. Our cookie policy
                      is designed to provide transparency about the data we collect and how we use it to enhance the user experience.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      We use cookies to track user interactions on our site, which allows us to offer a more tailored experience for dementia patients,
                      their families, and caregivers. For instance, cookies help us remember your preferences for easy navigation, and they enable
                      the personalization of health-related content based on your needs. Some cookies are essential for the functionality of our website, such as
                      those that enable you to access secured areas or submit forms.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      Additionally, we use cookies to collect analytics data to understand how visitors use our website. This helps us improve
                      the accessibility of information and resources for dementia patients, caregivers, and healthcare professionals.
                      If you have concerns about the use of cookies on our website, you may manage or disable cookies through your browser settings.
                      Please note that disabling certain cookies may limit your ability to use some features on our site.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      By continuing to use our website, you consent to our use of cookies as outlined in this policy. We may update our cookie policy from
                      time to time, so we encourage you to review it periodically to stay informed.
                    </Typography>
                  </DialogContent>

                  {/* Button for agreeing to cookies */}
                  <DialogActions sx={{ justifyContent: "center", margin: "0px 50px 0px 50px" }}>
                    <Button
                      color="success"
                      sx={{
                        margin: "0px 10px",
                        borderRadius: "10px",
                        width: "50%", // Adjust width for balance
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Agree
                    </Button>

                    {/* Button for rejecting cookies */}
                    <Button
                      color="error"
                      sx={{

                        margin: "0px 10px",
                        borderRadius: "10px",
                        width: "50%", // Adjust width for balance
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Reject
                    </Button>
                  </DialogActions>

                  <DialogActions sx={{ justifyContent: "center" }}>
                    <Button
                      sx={{
                        backgroundColor: "#b31c31",
                        "&&:hover": {
                          backgroundColor: "#b31c31",
                        },
                        width: {
                          xs: "100%", // Full width on extra-small screens (mobile)
                          sm: "80%",  // 80% width on small screens and above
                          md: "80%",  // 60% width on medium screens and above
                        },
                        margin: {
                          xs: "0px 0px 20px 0px", // Margin for extra-small screens
                          sm: "0px 0px 15px 0px", // Adjusted margin for small screens and above
                          md: "0px 0px 15px 0px", // Adjusted margin for medium screens and above
                        },
                        borderRadius: "10px",
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>



              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
       >
        <Alert onClose={handleCloseSnackbar} severity={snackbarMessage.includes('Error') ? 'success' : 'error'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </Grid>
    </div >
  );
}
