import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FullWidthSurveyPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    satisfaction: "",
    feedback: "",
    improvements: {
      quality: false,
      pricing: false,
      communication: false,
      support: false,
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        improvements: { ...prev.improvements, [name]: checked },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Survey Submitted:", formData);
    alert("Thank you for your feedback!");
    setFormData({
      name: "",
      email: "",
      satisfaction: "",
      feedback: "",
      improvements: {
        quality: false,
        pricing: false,
        communication: false,
        support: false,
      },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        py: 4,
        animation: `${fadeIn} 1s ease-in-out`,
      }}
    >
      <Container maxWidth="lg">
        <Card elevation={5} sx={{ borderRadius: 5,  }}>
          <Box
            sx={{
              backgroundColor: "#125782",
              color: "#fff",
              padding: 3,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              textAlign: "center",
            }}
          >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Assisted Living Facility Survey
            </Typography>
            <Typography variant="body1" sx={{ fontStyle: "italic" }}>
              Your feedback helps us provide the best care and improve our
              services.
            </Typography>
          </Box>

          <CardContent sx={{
            padding: "0px 2rem 0px 2rem"
          }}>
            <form onSubmit={handleSubmit}>
              {/* Name and Email Section */}
              <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ color: "#1976d2" }}>
                Personal Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        transition: "all 0.3s ease",
                        "& fieldset": {
                          borderColor: "#1976d2",
                        },
                      },
                      "&:hover .MuiOutlinedInput-root": {
                        borderColor: "#1976d2",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        transition: "all 0.3s ease",
                        "& fieldset": {
                          borderColor: "#1976d2",
                        },
                      },
                      "&:hover .MuiOutlinedInput-root": {
                        borderColor: "#1976d2",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Satisfaction Section */}
              <Box mt={4}>
                <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ color: "#1976d2" }}>
                  Overall Satisfaction
                </Typography>
                <RadioGroup
                  name="satisfaction"
                  value={formData.satisfaction}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="Very Satisfied"
                    control={<Radio color="primary" />}
                    label="Very Satisfied"
                    sx={{ color: "#1976d2" }}
                  />
                  <FormControlLabel
                    value="Satisfied"
                    control={<Radio color="primary" />}
                    label="Satisfied"
                    sx={{ color: "#1976d2" }}
                  />
                  <FormControlLabel
                    value="Neutral"
                    control={<Radio color="primary" />}
                    label="Neutral"
                    sx={{ color: "#1976d2" }}
                  />
                  <FormControlLabel
                    value="Dissatisfied"
                    control={<Radio color="primary" />}
                    label="Dissatisfied"
                    sx={{ color: "#1976d2" }}
                  />
                </RadioGroup>
              </Box>

              {/* Areas for Improvement Section */}
              <Box mt={4}>
                <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ color: "#1976d2" }}>
                  Areas for Improvement
                </Typography>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: "#125782",color: "#ffffff" }}>  
                    <Typography>What should we improve? (Select all that apply)</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="quality"
                            checked={formData.improvements.quality}
                            onChange={handleChange}
                            sx={{
                              "&.Mui-checked": {
                                color: "#1976d2",
                              },
                            }}
                          />
                        }
                        label="Service Quality"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="pricing"
                            checked={formData.improvements.pricing}
                            onChange={handleChange}
                            sx={{
                              "&.Mui-checked": {
                                color: "#1976d2",
                              },
                            }}
                          />
                        }
                        label="Pricing"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="communication"
                            checked={formData.improvements.communication}
                            onChange={handleChange}
                            sx={{
                              "&.Mui-checked": {
                                color: "#1976d2",
                              },
                            }}
                          />
                        }
                        label="Food Quality"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="communication"
                            checked={formData.improvements.communication}
                            onChange={handleChange}
                            sx={{
                              "&.Mui-checked": {
                                color: "#1976d2",
                              },
                            }}
                          />
                        }
                        label="Food Quality"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="support"
                            checked={formData.improvements.support}
                            onChange={handleChange}
                            sx={{
                              "&.Mui-checked": {
                                color: "#1976d2",
                              },
                            }}
                          />
                        }
                        label="Customer Support"
                      />
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              </Box>

              {/* Additional Feedback Section */}
              <Box mt={4}>
                <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ color: "#1976d2" }}>
                  Additional Feedback
                </Typography>
                <TextField
                  fullWidth
                  label="Share your thoughts or suggestions"
                  name="feedback"
                  multiline
                  rows={4}
                  value={formData.feedback}
                  onChange={handleChange}
                  placeholder="Let us know how we can improve."
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      transition: "all 0.3s ease",
                      "& fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                    "&:hover .MuiOutlinedInput-root": {
                      borderColor: "#1976d2",
                    },
                  }}
                />
              </Box>

              {/* Submit Button */}
              <Box mt={4} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    px: 6,
                    py: 1.5,
                    fontSize: "1.2rem",
                    backgroundColor: "#1976d2",
                    "&:hover": {
                      backgroundColor: "#115293",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  Submit Survey
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default FullWidthSurveyPage;
