
import React from 'react';
import Footer from '../components/Footer';
import { Container, Grid, Paper,Link, Typography, List, ListItem, ListItemText, Box,  Card, CardContent, Divider } from '@mui/material';
import Header from "../components/Header";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const privacySections = [
  {
    title: '1. Information Collection',
    content: 'We collect personal information from our users, including but not limited to names, email addresses, IP addresses, and browsing data. This information is used to enhance user experience and provide personalized content. We also collect information from user interactions with the website, such as clicks, search queries, and other usage patterns.'
  },
  {
    title: '2. How We Use Your Information',
    content: 'The data we collect is used to process your requests, improve services, and send marketing communications (if you opt-in). We may also use your data for analytics and user engagement purposes, to better understand user behavior, and to improve the functionality and content of our website.'
  },
  {
    title: '3. Data Sharing and Disclosure',
    content: 'We may share your data with trusted third-party service providers who assist us in operating our services, such as payment processors, data storage services, and email communication tools. We will not sell or rent your personal information to third parties. In some cases, we may disclose your data to comply with legal obligations or protect our legal rights.'
  },
  {
    title: '4. Cookies and Tracking Technologies',
    content: 'We use cookies and similar tracking technologies to enhance your browsing experience, personalize content, and analyze website traffic. Cookies are small text files stored on your device that help us track your preferences and improve site performance. You can manage cookie preferences through your browser settings, but disabling cookies may impact website functionality.'
  },
  {
    title: '5. International Data Transfers',
    content: 'Your information may be transferred and stored on servers located outside your home country, including in regions with differing privacy laws. By using our services, you consent to the transfer of your data to these locations, with the understanding that we will implement appropriate safeguards to protect your information.'
  },
  {
    title: '6. Data Retention',
    content: 'We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law. Once the retention period expires, we will securely delete or anonymize your data in accordance with industry standards.'
  },
  {
    title: '7. User Rights',
    content: 'You have certain rights regarding your personal data, including the right to access, correct, delete, or object to the processing of your data. You may also have the right to restrict or request the portability of your data in some cases. To exercise these rights, please contact us at the details provided below.'
  },
  {
    title: '8. Data Security',
    content: 'We take the security of your personal information seriously and use a variety of security measures, including encryption, secure access protocols, and regular security audits, to protect your data from unauthorized access, disclosure, alteration, or destruction. However, no data transmission or storage method is completely secure, and we cannot guarantee the absolute security of your information.'
  },
  {
    title: '9. Third-Party Links',
    content: 'Our website may contain links to third-party websites or services that are not operated by us. We are not responsible for the privacy practices of these external sites and encourage you to review their privacy policies before providing any personal information.'
  },
  {
    title: '10. Children’s Privacy',
    content: 'Our services are not intended for children under the age of 13, and we do not knowingly collect or solicit personal information from children. If we become aware that we have inadvertently collected personal data from a child, we will take steps to delete such information from our records.'
  },
  {
    title: '11. Updates to this Privacy Policy',
    content: 'We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. When we make changes, we will update the effective date at the top of the policy and notify users of any significant updates through appropriate communication channels.'
  },
];

const PrivacyPolicyPage = () => {
  const location = useLocation();
  
    useEffect(() => {
      if (location.path = "/policy") {
        document.title = "Informed ALF Privacy Policy";
      } 
      // else {
      //   document.title = "Informed ALF"; // Set a default title for other routes
      // }
    }, [location.path]);
  return (
    <div>
      <Header />
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Grid container spacing={4}>
        {/* Sidebar */}
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{
            p: 3,
            borderRadius: 2,
            backgroundColor: '#FBF7E9', // New darker purple background for sidebar
            position: 'sticky',
            top: 20,
            height: 'calc(90vh - 40px)',
            overflowY: 'auto',
            borderLeft: '4px solid #9A8C98', // Light purple border accent
          }}>
            <Typography variant="h6" sx={{
              fontWeight: 'bold', mb: 3, color: '#125782', // Light pinkish color for title
              textAlign: 'center', textTransform: 'uppercase', letterSpacing: 1,
            }}>
              Table of Contents
            </Typography>
            <List sx={{ padding: 0 }}>
              {privacySections.map((section, index) => (
                <ListItem key={index} sx={{
                  mb: 2, display: 'flex', alignItems: 'center',
                  '&:hover': { backgroundColor: '#C9ADA7', cursor: 'pointer' } // Soft pink hover color
                }}>
                  <ListItemText
                    primary={<Link href={`#section-${index}`} sx={{
                      color: '#125782', fontWeight: 'medium', // Updated text color for items
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline', color: '#125782' } // Slightly lighter hover color
                    }}>
                      {section.title}
                    </Link>}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} md={9}>
          <Box sx={{ p: 3 }}>
            {/* Title Section */}
            <Box sx={{ textAlign: 'center', mb: 5 }}>
              <Typography variant="h4" sx={{
                backgroundColor: '#125782', // Dark purple for main title background
                color: 'white',
                p: 3,
                borderRadius: 2,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: 1.2
              }}>
                Privacy Policy
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                Effective Date: 2024-07-01
              </Typography>
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Privacy Sections in Modernized Cards */}
            {privacySections.map((section, index) => (
              <Card key={index} sx={{
                mb: 4,
                borderRadius: 2,
                boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#F7F7FA', // Soft gray background for card
                border: '1px solid #D1D9E0',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-3px)',
                  backgroundColor: '#E0EAF2', // Lighter hover color
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
                },
              }}>
                <CardContent id={`section-${index}`}>
                  <Typography variant="h5" sx={{
                    color: '#4A90E2', // Primary blue for section title
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    mb: 2,
                    letterSpacing: 1,
                  }}>
                    {section.title}
                  </Typography>
                  <Divider sx={{ my: 2, backgroundColor: '#4A4E69' }} />
                  <Typography variant="body2" sx={{ lineHeight: 1.8, mb: 2, color: '#333' }}>
                    {section.content}
                  </Typography>
                </CardContent>
              </Card>
            ))}

            {/* Contact Information */}
            <Box sx={{ textAlign: 'center', mt: 6 }}>
              <Divider sx={{ my: 3 }} />
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#4A90E2' }}>
                Contact Us
              </Typography>
              <Typography variant="body1" color="text.secondary">
                If you have any questions about this Privacy Policy, please contact us at:&nbsp;
                <Link href="https://informedalf.com" target="_blank" rel="noopener noreferrer" style ={{ textDecoration: 'none', color: '#4A90E2' }}>
                  Informed Support
                </Link>.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicyPage;
