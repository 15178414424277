import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText, Box,
} from '@mui/material';
import { NavLink, Link } from 'react-router-dom';
import { CircularProgress } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LoginIcon from '@mui/icons-material/Login';
// import axios from 'axios';
import Swal from 'sweetalert2';
import image from '../images/logo3.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';



export default function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const [open, openChange] = useState(false);
  const [CurrentRole, setCurrentRole] = useState("");
  const [CurrentName, setCurrentName] = useState("");
  const [CurrentEmail, setCurrentEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    role: '',
    terms: false,
  });


  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // true if captcha completed
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleAgreeTermsChange = (e) => {
    setAgreeTerms(e.target.checked === true);
  };
  const functionopenpopup = () => {
    openChange(true);
  };

  const closepopup = () => {
    handleClearForm();
    setErrors({});
    openChange(false);
  };



  // const handleRoleChange = (event) => {
  //   setCurrentRole(event.target.value);
  // };


  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setCurrentRole(selectedRole);

    // Clear the error if a role is selected
    if (selectedRole) {
      setErrors((prevErrors) => ({ ...prevErrors, role: "" }));
    }
  };

  // const handleNameChange = (event) => {
  //   setCurrentName(event.target.value);
  // };

  const handleNameChange = (event) => {
    const { value } = event.target;
    setCurrentName(value);

    // Clear the error if the input is not empty
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
    }
  };


  function validateAndSetName(event, setErrors, setCurrentName) {
    try {
      const value = event.target.value;

      if (/[\d]/.test(value)) {
        throw new Error("Name cannot contain numbers.");
      }

      setErrors({ name: "" });
      setCurrentName(value);
    } catch (error) {
      setErrors({ name: error.message });
    }
  }

  const handleEmailChange = (event) => {
    setCurrentEmail(event.target.value);

    if (event.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleClearForm = () => {
    setCurrentName('');
    setCurrentEmail('');
    setCurrentRole("");
    setCaptchaVerified(false);
    // setSnackbarMessage('Form cleared!');
    // setSnackbarOpen(true);
  };



  const validateForm = () => {
    let formErrors = {};
    if (!CurrentName) formErrors.name = 'Name is required';
    if (!CurrentEmail) formErrors.email = 'Email is required';
    if (!CurrentRole) formErrors.role = 'Role is required';
    if (!agreeTerms) formErrors.terms = 'Please agree to terms and conditions';
    console.log(formErrors);
    return formErrors;
  };

  const submitFormData = async () => {


    const formErrors = validateForm();
    setErrors(formErrors);

    if (/[\d]/.test(CurrentName)) {
      formErrors.name = "Name cannot contain numbers.";
      setErrors(formErrors);
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation
    if (!emailRegex.test(CurrentEmail)) {
      formErrors.email = "Please enter a valid email address.";
      setErrors(formErrors);
    }
    if (Object.keys(formErrors).length > 0) return; // Do not submit if there are errors

    if (!agreeTerms) {
      formErrors.terms = 'Please agree to terms and conditions';
      setErrors(formErrors);
      console.log(formErrors);
      return
    }
    if (!captchaVerified) {
      setSnackbarMessage('Please complete the CAPTCHA');
      setSnackbarOpen(true);
      return;
    }

    let data = {
      name: CurrentName,
      email: CurrentEmail,
      current_role: CurrentRole,
      agree: agreeTerms
    };

    try {
      setIsLoading(true);
      const response = await fetch('https://app.dementiawhiz.com/service/api/enquiries', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Thank you for showing your interest in Informed Assisted Living Facility . We will get back to you soon.",
          showConfirmButton: false,
          timer: 3000,
        });
        closepopup();
        setIsLoading(false);
        setCaptchaVerified(false); // Reset CAPTCHA status
      } else {
        setSnackbarMessage("Failed to save data. Please try again.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const drawer = (
    <Grid container direction="column" sx={{ padding: 2, bgcolor: "#f8f9fa" }}>
      <Grid item sx={{ marginBottom: 2 }}>
        <ListItem
          button
          component={NavLink}
          to="/about"
          sx={{
            "&:hover": {
              bgcolor: "#e3f2fd",
              borderRadius: "10px",
            },
          }}
        >
          <ListItemText
            to="/"
            primary="About Us"
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#125782",
              textAlign: "center",
            }}
          />
        </ListItem>
      </Grid>

      <Grid item sx={{ marginBottom: 2 }}>
        <ListItem
          button
          component={NavLink}
          to="/"
          onClick={handleDrawerToggle}
          sx={{
            "&:hover": {
              bgcolor: "#e3f2fd",
              borderRadius: "10px",
            },
          }}
        >
          {/* <ListItemText
            primary="Sample Education"
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#125782",
              textAlign: "center",
            }}
          /> */}
        </ListItem>
      </Grid>

      <Grid item>
        <Button
          onClick={() => {
            functionopenpopup();
            handleDrawerToggle(); // Close drawer after clicking JOIN NOW
          }}
          sx={{
            width: "100%",
            color: "white",
            bgcolor: "#b31c31",
            padding: "10px 0",
            fontSize: "18px",
            fontWeight: "700",
            borderRadius: "10px",
            "&:hover": {
              bgcolor: "#a01729",
            },
          }}
        >
          JOIN NOW
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container sx={{ bgcolor: '#ffffff' }}>
      <AppBar component="nav" position="static" elevation={0} sx={{ padding: '16px 0', bgcolor: '#ffffff' }}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <Link href="/" style={{ textDecoration: 'none' }}>
                <img src={image} alt="logo" style={{ width: '58px', height: '59px' }} />
              </Link>
              {/* <Typography sx={{ fontSize: '28px', fontWeight: 'bolder', color: '#125782', padding: '5px 0 0 15px' }}>
                Informed ALF
              </Typography> */}
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Typography style={{ fontSize: '28px', fontWeight: '600', color: '#125782', padding: '5px 0 0 0px' }}>
                  Informed ALF
                </Typography>
              </Link>
            </Grid>
            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ color: '#125782' }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <NavLink
                  to="/about"
                  style={({ isActive }) => ({
                    textDecoration: 'none',
                    marginRight: '20px',
                    color: isActive ? '#b31c31' : '#125782', // Highlight active link
                    fontWeight: isActive ? 'bolder' : '550',   // Make active link bold
                  })}
                >
                  <Typography sx={{ fontSize: '19px', fontFamily: 'Poppins', fontWeight: '550', }}>
                    About Us
                  </Typography>
                </NavLink>
                <NavLink
                  to="#"
                  style={({ isActive }) => ({
                    textDecoration: 'none',
                    marginRight: '20px',
                    color: isActive ? '#125782' : '#125782', // Highlight active link
                    fontWeight: isActive ? 'bolder' : '550',   // Make active link bold
                  })}
                >
                  {/* <Typography sx={{ fontSize: '19px', fontFamily: 'Poppins', fontWeight: '550', }}>
                    Sample Education
                  </Typography> */}
                </NavLink>
                {/* <NavLink to="#" style={{ textDecoration: 'none', marginRight: '20px' }}>
                  <Typography sx={{ fontSize: '19px', fontWeight: '550', fontFamily: 'Poppins', color: '#125782' }}>
                    Sample Education
                  </Typography>
                </NavLink> */}

                <Button onClick={functionopenpopup} sx={{ fontSize: '19px', fontWeight: '600', fontFamily: 'Poppins', color: 'white', bgcolor: '#b31c31', borderRadius: '10px', '&:hover': { bgcolor: '#b31c31', color: 'white' }, marginRight: '20px' }}>
                  JOIN NOW
                </Button>
                <Button href="https://app.dementiawhiz.com" target='_blank' sx={{ fontSize: '19px', fontWeight: '600', fontFamily: 'Poppins', color: 'white', bgcolor: '#a97adb', borderRadius: '10px', '&:hover': { bgcolor: '#a97adb', color: 'white' } }}>
                  Login
                  <IconButton style={{ float: "right", color: "white" }}>
                    <LoginIcon />
                  </IconButton>
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }} // Better open performance on mobile.
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
      <Dialog
        open={open}
        onClose={closepopup}
        fullWidth
        maxWidth="sm"
        alignItems="center"
      >
        <DialogTitle sx={{ bgcolor: '#FBF7E9', display: 'flex', justifyContent: 'space-between', alignItems: 'center', pl: 5, pt: 3 }} variant="h5">
          <Typography sx={{ bgcolor: '#FBF7E9', flex: 1, textAlign: 'center', fontSize: '25px', fontWeight: '600', fontFamily: 'Poppins', color: '#125782' }}>JOIN NOW</Typography>
          <IconButton onClick={closepopup} sx={{ ml: 2 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ bgcolor: '#FBF7E9' }}>
          <Stack spacing={2} margin={2}>
            {/* <TextField
              type="text"
              required
              variant="outlined"
              label="Name"
              name="Name"
              value={CurrentName}
              onChange={handleNameChange}
              error={!!errors.name}
              helperText={errors.name}
            /> */}
            <TextField
              type="text"
              required
              variant="outlined"
              label="Name"
              name="Name"
              value={CurrentName}
              onChange={(e) => {
                const regex = /^[a-zA-Z\s]*$/; // Allows only letters and spaces
                if (regex.test(e.target.value)) {
                  handleNameChange(e); // Only update state if the value matches the regex
                }
              }}
              error={!!errors.name}
              helperText={errors.name}
            />



            <TextField
              required
              variant="outlined"
              label="Email"
              name="Email"
              value={CurrentEmail}
              onChange={handleEmailChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <FormControl required error={!!errors.role} sx={{ m: 2, minWidth: 150 }}>
              <InputLabel id="demo-simple-select-required-label">Current-Role </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                name="Currentrole"
                id="demo-simple-select-required"
                value={CurrentRole}
                label="CurrentRole"
                onChange={handleRoleChange}
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value="Assisted Living Facility">Assisted Living Facility</MenuItem>
                <MenuItem value="Caregiver">Caregiver</MenuItem>
                <MenuItem value="Family Member">Family Member</MenuItem>
                <MenuItem value="Patient">Patient</MenuItem>
              </Select>
              {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
              <FormControl required error={!!errors.agree} component="fieldset" variant="standard">

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeTerms}
                      onChange={handleAgreeTermsChange}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">Agree to </Typography>
                      <Button
                        onClick={() => window.open("/Terms", "_blank")}
                        sx={{
                          textTransform: "none",
                          padding: 0,
                          marginLeft: "4px",
                          color: "#125782",
                          fontSize: "14px",
                          fontWeight: "bold",
                          "&:hover": {
                            textDecoration: "underline",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        Terms & Conditions
                      </Button>
                    </Box>
                  }
                />
              </FormControl>  {/* <FormHelperText>Required</FormHelperText> */}
              {errors.terms && (
                <FormHelperText variant="caption" sx={{ color: "red" }}>{errors.terms}</FormHelperText>
              )}
            </FormControl>

            <ReCAPTCHA
              style={{ alignSelf: 'center' }}
              sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR" // Replace with your ReCAPTCHA site key
              onChange={handleCaptchaChange}
            />
            <Button
              onClick={submitFormData}
              mt={2}
              sx={{
                borderRadius: "13px",
                bgcolor: "#b31c31",
                color: "white",
                '&:hover': {
                  bgcolor: '#a01729',
                },
                fontSize: "18px",
                fontWeight: "700",
                fontFamily: "poppins",
              }}
            >
              {/* Submit */}
              {isloading ? (
                <Box display="flex" alignItems="center">
                  <Typography variant="body4" marginRight={1}>
                    Submitting
                  </Typography>
                  <CircularProgress size={18} sx={{ color: '#FFFFFF' }} />
                </Box>
              ) : (
                "Submit"
              )}
            </Button>
          </Stack>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
       >
        <Alert onClose={handleCloseSnackbar} severity={snackbarMessage.includes('Error') ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar> */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={captchaVerified ? "success" : "warning"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
