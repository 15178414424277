import React from "react";
import Header from "../components/Header";
import { styled } from "@mui/system";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ReCAPTCHA from 'react-google-recaptcha';
import { CircularProgress } from "@mui/material";
import Footer from '../components/Footer';

// import images
// import about1 from "../images/about_page_banner.webp";
// import about_card1 from "../images/about_card1.png";
import about_circle from "../images/about_circle1.webp";
import cont5 from "../images/5thcont.webp";
import thenewyorktimes from "../images/thenewyorktimes.avif";
import usatoday from "../images/usatoday.avif";
import forbes from "../images/forbes.avif";
// import daydrink from "../images/day-drink.jpg";
// import mobile from "../images/mobile2.png";
import image from "../images/logo3.png";
import sun from "../images/sun.png";
import { useState } from "react";
import Swal from 'sweetalert2';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#b31c31",
  color: "white",
  fontWeight: "bold",
  padding: "10px 20px",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#8f1625",
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "20px", // Rectangle with rounded corners
    border: "2px solid #ffffff", // Add border for styling
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)", // Subtle shadow
    animation: "slideIn 0.4s ease-in-out", // Animation for smooth appearance
  },
  "@keyframes slideIn": {
    from: { transform: "translateY(50px)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
  },
}));

const About = () => {
  const initialFormState = {
    name: "",
    email: "",
    phone: "",
    // relationship: "",
    reason: "",
    notes: "",
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [CurrentRole, setCurrentRole] = React.useState("");
  const [CurrentName, setCurrentName] = React.useState("");
  const [CurrentEmail, setCurrentEmail] = React.useState("");
  const [open, openchange] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [agreeError, setAgreeError] = useState('');
  
  const [errors, setErrors] = useState({
    CurrentName: '',
    CurrentEmail: '',
    CurrentRole: ''
  });
  
  const location = useLocation();

  useEffect(() => {
    if (location.path = "/About") {
      document.title = "Informed ALF About Us";
    } 
    // else {
    //   document.title = "Informed ALF"; // Set a default title for other routes
    // }
  }, [location.path]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const functionopenpopup = () => {
    openchange(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  // Function to handle closing the modal
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCancel = () => {
    setOpenpop(false);
    setFormData(initialFormState);
    setErrors({
      CurrentName: '',
      CurrentEmail: '',
      CurrentRole: ''
    });
  }
  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // true if captcha completed
  };
  const closepopup = () => {
    openchange(false);
    clearForm();
    setErrors({
      CurrentName: '',
      CurrentEmail: '',
      CurrentRole: ''
    });
  };

  const clearForm = () => {
    setCurrentName(''); // Reset name to empty string
    setCurrentEmail(''); // Reset email to empty string
    setCurrentRole(''); // Reset role to empty string
    setAgreeTerms(false); // Reset agreeTerms checkbox
    setNameError(null); // Clear name error
    setEmailError(null); // Clear email error
    setRoleError(null); // Clear role error
    setCaptchaVerified(false); // Reset captcha state if needed
  };
  // const handleSubmit = () => {
  //   // Submit logic (e.g., API call)
  //   console.log("Form submitted: ", formData);
  //   setOpenpop(false);
  //   alert("Thank you! Your interest has been submitted.");
  // };
  const validateForm = () => {
    const newErrors = {};

    // Full Name validation
    if (!formData.name || /[^A-Za-z\s]/.test(formData.name)) {
      newErrors.name = "Full Name is required and must contain only letters.";
    }

    // Email validation
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Phone validation (optional)
    if (formData.phone && formData.phone.length !== 10) {
      newErrors.phone = "Phone number should be exactly 10 digits.";
    }

    // Relationship validation
    // if (!formData.relationship) {
    //   newErrors.relationship = "Relationship to patient is required.";
    // }

    // Reason validation
    if (!formData.reason) {
      newErrors.reason = "Reason for interest is required.";
    }

    // Notes validation (optional)
    // No validation needed for notes in this case, as it's optional

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!validateForm()) {
      setIsLoading(false);
      return;  // Stop the submission process if validation fails
    }
    if (!captchaVerified) {
      setSnackbarMessage('Please complete the CAPTCHA');
      setSnackbarOpen(true);
      setIsLoading(false);
      return;
    }
    const apiPayload = {
      fullName: formData.name,
      emailAddress: formData.email,
      phoneNumber: formData.phone,
      // relationToPatient: formData.relationship,
      interestReason: formData.reason,
    };
    try {
      const response = await fetch("https://app.dementiawhiz.com/service/api/express-interest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiPayload),
      });

      if (!response.ok) {
        throw new Error("Failed to submit your interest.");
      }
      if (response.status === 201) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Thank you for showing your interest in informed Alf. We will get back to you soon.",
          showConfirmButton: false,
          timer: 3000,
        });
        closepopup();
        setCaptchaVerified(false); // Reset CAPTCHA status
      } else {
        // setSnackbarMessage("Failed to save data. Please try again.");
        // setSnackbarOpen(true);
      }
      const data = await response.json();
      console.log("API Response:", data);
      setOpenpop(false);
      // alert("Thank you! Your interest has been submitted.");
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error.message);
      // alert("Failed to submit your interest. Please try again later.");
      setSnackbarMessage("Failed to submit your interest. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
      setOpenpop(false)
    }
    setFormData(initialFormState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const teamMembers = [
    {
      name: "Nick Allen",
      position: "CEO & Founder",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    },
    {
      name: "Ian Andersen",
      position: "Co-Founder & CGO",
      image: "https://images.prismic.io/sunnysidetest/fa77fe0a-63f2-4fbe-9b0c-3199dfb980ab_02-Ian-Andersen.png?auto=compress,format?w=384&q=100",
    },
    {
      name: "Jane Smith",
      position: "Head of Customer Experience",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    },
    {
      name: "Jane Smith",
      position: "Chief Product and Technology Officer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Software Engineer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Customer Experience Manager",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Software Engineer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Software Engineer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Customer Experience Manager",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Senior Product Manager",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Customer Experience Manager",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Head of Social Media & Influencer Partnerships",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Podcast Host & Content Specialist",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Social Media Coordinator",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Head of Lifecycle",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    }, {
      name: "Jane Smith",
      position: "Software Engineer",
      image: "https://images.prismic.io/sunnysidetest/ff740c00-6b69-4916-a36d-e5ee41eb7c24_01-Nick-Allen.png?auto=compress,format?w=384&q=100",
    },
    // Add more members as needed
  ];
  const [openpop, setOpenpop] = useState(false);


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    // relationship: "",
    reason: "",
    notes: "",
  });

  return (
    <>
      <Header />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
       >
        <Alert
          onClose={handleCloseSnackbar}
          severity={captchaVerified ? "success" : "warning"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        sx={{
          xs: {
            justifyContent: "center",
            alignItems: "center",
          },
          padding: {
            xs: "0px",
            sm: "0px 0px 30px 0px",
            md: "0px 88px 80px 88px",
            lg: "0px 88px 10px 88px",
          },
        }}
      >
        {/* Text Section */}
        <Grid
          item
          xs={12}
          sm={9}
          md={5.8}
          lg={5}
          order={{ xs: 2, lg: 1, md: 1 }} // On small screens, text will appear below image
          sx={{
            justifyContent: "center",
            alignItems: "center",
            margin: {
              xs: "10px 0px 0px 25px", // Extra small screens
              sm: "0px 0px 0px 90px", // Small screens
              md: "120px 0px 0px 0px", // Medium screens
              lg: "0px", // Large screens
              xl: "0px", // Extra large screens
            },
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: {
                xs: "0px 0px 10px 0px",
                md: "0px 0px 10px 20px",
                sm: "0px 0px 30px 10px",
                lg: "150px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "44px",
                md: "52px",
                lg: "64px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "left",
              lineHeight: {
                xs: "32px",
                sm: "47px",
                md: "52px",
                lg: "64px",
              },
            }}
          >
            "Memories may fade, but love and kindness endure forever."
          </Typography>
        </Grid>

        {/* Image Section */}
        <Grid
          item
          xs={10}
          md={6.2}
          sm={11.5}
          lg={7}
          display="flex"
          justifyContent="center"
          order={{ xs: 1, lg: 2 }} // On small screens, image will appear above text
          sx={{
            margin: {
              xs: "10px 0px 0px 25px", // Extra small screens
              sm: "0px 0px 0px 20px", // Small screens
              md: "0px 0px 0px 0px", // Medium screens
              lg: "0px 0px 0px 0px", // Large screens
              xl: "0px 0px 0px 0px", // Extra large screens
            },
          }}
        >
          <Box
            component="img"
            // src={about1}
            src="https://img.freepik.com/premium-vector/elderly-couple-is-together-park-grandfather-pushes-grandma039s-wheelchair_923732-203.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
            alt="logo"
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", md: "760px", lg: "660px" }, // limits width for larger screens
              height: "auto", // keeps aspect ratio
              borderRadius: "20px",
            }}
          />
        </Grid>
      </Grid>



      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}

      <Grid
        container
        sx={{
          bgcolor: "#FBF7E9",
          padding: {
            xs: "10px", // Smaller padding for mobile
            sm: "30px 10px 10px 10px",
            md: "20px",
            lg: "80px 88px",
            xl: "100px 120px",
          },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={8} md={6} lg={4} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={sun}
            alt="logo"
            style={{
              width: "100%",
              maxWidth: "53px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }} >
          <Typography
            sx={{
              textAlign: "center",
              padding: {
                xs: "10px 0",
                lg: "20px 0px 0px 20px",
              },
              fontSize: {
                xs: "24px",
                lg: "48px",
                md: "48px",
                sm: "48px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
            }}
          >
            What we stand for
          </Typography>
        </Grid>


        <Grid
          item
          xs={12}
          md={12}
          lg={9}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        ><Grid item xs={12} md={12} lg={12} sx={{ padding: "20px", textAlign: "center" }}>
            <Typography
              sx={{
                padding: {
                  xs: "0px",
                  lg: "10px 0 40px 20px",
                },
                fontSize: {
                  xs: "16px",
                  sm: "30px",
                  md: "22px",
                  lg: "25px",
                },
                fontWeight: "300",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "24px",
                  lg: "35px",
                  md: "30px",
                  sm: "40px",
                },
              }}
            >
              At Informed ALF, we are deeply committed to providing compassionate, dignified, and personalized care for those living with dementia. Our mission is to create a safe and nurturing environment where residents feel understood, valued, and at peace. We believe that every individual deserves respect and meaningful engagement, regardless of memory loss.
            </Typography>
          </Grid>
        </Grid>

        {/* cards starting */}

        <Grid
          container
          spacing={2}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "16px",
              sm: "16px",
              md: "32px",
              lg: "64px 48px",
              xl: "80px 88px",
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/** Card 1 */}
          <Grid
            item
            xs={11}
            // sm={8}
            md={4}
            sx={{
              display: "flex",

              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: { xs: "16px 16px 32px 16px", lg: "16px 48px 64px 48px" },
              margin: { xs: "0px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },
            }}
          >
            <img
              // src={about_card1}
              src="https://img.freepik.com/free-photo/customer-consultant-meeting-home_74855-2731.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
              alt="Empathy & Understanding"
              style={{
                width: "100%",
                maxWidth: "191px",
                height: "auto",
                borderRadius: "20px",
                objectFit: "cover",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                fontSize: { xs: "25px", lg: "28px" },
                lineHeight: { sm: "32px", lg: "35px" },
              }}
            >
              Empathy & Understanding
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "19px", lg: "21px" },
                fontWeight: 400,
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: { sm: "29px" },
                mt: 1,
              }}
            >
              We treat every resident with patience, empathy, and a genuine desire to understand their unique journey.
            </Typography>
          </Grid>

          {/** Card 2 */}
          <Grid
            item
            xs={11}
            // sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: { xs: "16px", lg: "16px 48px 64px 48px" },
              margin: { xs: "0px 0px 20px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

            }}
          >
            <img
              // src={about_card1}
              src="https://img.freepik.com/free-photo/front-view-happy-family-looking-picture-album_23-2149515337.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
              alt="Family Support"
              style={{
                width: "100%",
                maxWidth: "201px",
                height: "auto",
                borderRadius: "20px",
                objectFit: "cover",

              }}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                fontSize: { xs: "25px", lg: "28px" },

              }}
            >
              Family Support
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "19px", lg: "21px" },
                fontWeight: 400,
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: { sm: "29px" },
                mt: 1,
              }}
            >
              We value family involvement and strive to create a partnership with families, offering resources and support to ease their journey alongside their loved ones.
            </Typography>
          </Grid>

          {/** Card 3 */}
          <Grid
            item
            xs={12}
            // sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: { xs: "16px", lg: "16px 48px 64px 48px" },
              margin: { xs: "0px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

            }}
          >
            <img
              // src={about_card1}
              src="https://img.freepik.com/free-photo/medium-shot-woman-getting-breakfast_23-2149046133.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
              alt="Dignity in Care"
              style={{
                width: "100%",
                maxWidth: "191px",
                height: "auto",
                borderRadius: "20px",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                fontSize: { xs: "25px", lg: "28px" },
              }}
            >
              Dignity in Care
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "19px", lg: "21px" },
                fontWeight: 400,
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: "29px",
                mt: 1,
              }}
            >
              We approach care with the utmost respect, ensuring that each resident is treated with dignity, allowing them to retain a sense of self-worth and purpose.
            </Typography>
          </Grid>
        </Grid>


        {/* card4 */}
        <Grid container spacing={2} sx={{ bgcolor: "#FBF7E9", padding: "16px" }}>
          {/* Card 1 */}
          <Grid item xs={12} md={4} sx={{ padding: { xs: "8px", lg: "16px 48px 64px 48px" } }}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12}
                sx={{

                  margin: { xs: "0px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

                }}
              >
                <img
                  // src={about_card1}
                  src="https://www.performancehealthus.com/hubfs/patient%20safety%20at%20end%20of%20life-1.png"
                  alt="logo"
                  style={{
                    width: "100%",
                    maxWidth: "291px",
                    height: "138px",
                    borderRadius: "20px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: { xs: "25px", lg: "28px" },
                    fontWeight: 600,
                    fontFamily: "Recoleta medium",
                    color: "#124A2C",
                    textAlign: "center",
                    lineHeight: { xs: "28px", lg: "30px" },
                    mt: 2,
                  }}
                >
                  Safety & Comfort
                </Typography>
              </Grid>
              <Grid item xs={11.5}>
                <Typography
                  sx={{
                    fontSize: { xs: "19px", lg: "21px" },
                    fontWeight: 400,
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: "29px",
                    mt: 1,
                  }}
                >
                  Our facility is designed with the highest standards of safety and comfort in mind, using friendly layouts to reduce anxiety and enhance well-being.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Card 2 */}
          <Grid item xs={12} md={4} sx={{ padding: { xs: "8px", lg: "16px 48px 64px 48px" } }}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12}
                sx={{
                  margin: { xs: "23px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

                }}>
                <img
                  // src={about_card1}
                  src="https://img.freepik.com/premium-photo/medical-team-stacking-hands_274689-12480.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
                  alt="logo"
                  style={{
                    width: "100%",
                    maxWidth: "291px",
                    height: "138px",
                    borderRadius: "20px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: { xs: "25px", lg: "28px" },
                    fontWeight: 600,
                    fontFamily: "Recoleta medium",
                    color: "#124A2C",
                    textAlign: "center",
                    lineHeight: { xs: "29px", lg: "30px" },
                    mt: 2,
                  }}
                >
                  Our Mission & Vision
                </Typography>
              </Grid>
              <Grid item xs={11.5}>
                <Typography
                  sx={{
                    fontSize: { xs: "19px", lg: "21px" },
                    fontWeight: 400,
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: "29px",
                    mt: 1,
                  }}
                >
                  Our mission is to provide compassionate, respectful, and person-centered care that enhances the quality of life for individuals living with dementia.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} md={4} sx={{ padding: { xs: "8px", lg: "16px 48px 64px 48px" } }}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={11} sx={{
                margin: { xs: "23px 0px 0px 20px", sm: "0px 0px 0px 0px", lg: "0px 0px 0px 0px" },

              }}>
                <img
                  // src={about_card1}
                  src="https://img.freepik.com/premium-photo/happy-volunteer-park_13339-106901.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
                  alt="logo"
                  style={{
                    width: "100%",
                    maxWidth: "291px",
                    height: "138px",
                    borderRadius: "20px",
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  sx={{
                    fontSize: { xs: "25px", lg: "28px" },
                    fontWeight: 600,
                    fontFamily: "Recoleta medium",
                    color: "#124A2C",
                    textAlign: "center",
                    lineHeight: { xs: "28px", lg: "30px" },
                    mt: 2,
                  }}
                >
                  Volunteer & Career Opportunities
                </Typography>
              </Grid>
              <Grid item xs={11.5}>
                <Typography
                  sx={{
                    fontSize: { xs: "19px", lg: "21px" },
                    fontWeight: 400,
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: "29px",
                    mt: 1,
                  }}
                >
                  We welcome compassionate individuals to join our community as volunteers or team members. Explore our opportunities and become part of a rewarding mission.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      {/* cards end */}

      {/* 3rd container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center", // Fix 'alignItems' typo

          padding: {
            xs: "0px",
            sm: "0px 48px 0px 48px",
            md: "0px 88px 0px 88px",
            lg: "80px 88px",
            xl: "0px",
          },
        }}
      >
        <Grid
          item
          xs={9}
          sm={12}
          md={12}
          lg={8}
          sx={{
            justifyContent: "center",
            alignItems: "center", // Fix 'alignItems' typo
          }}
        >

          <Typography
            sx={{
              padding: {
                xs: "30px 0px 35px 0px",
                sm: "24px 0px 10px 0px",
                md: "24px 0px 30px 0px",
                lg: "24px 0px 0px 0px",
                xl: "24px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "44px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "500",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "center",
              lineHeight: {
                xs: "30px",
                sm: "45px",
                md: "20px",
                lg: "45px",
                xl: "20px",
              },
            }}
          >
            Our patients' average experience within the first 30 days
          </Typography>
        </Grid>





        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            padding: { xs: "10px", lg: "48px 0px" },
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {/* Map through the data for Grid Items */}
          {[
            { value: "49%", label: "PHYSICAL HEALTH & MOBILITY" },
            { value: "80%", label: "SLEEP QUALITY" },
            { value: "65%", label: "COMMUNICATION AND EXPRESSION" },
          ].map((item, index) => (
            <Grid
              key={index}
              item
              xs={12} // Full width on small devices
              sm={5} // 2 items per row on medium devices
              lg={3} // 3 items per row on large devices
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: { xs: "15px 10px", lg: "40px" },
              }}
            >
              {/* Value Typography */}
              <Typography
                sx={{
                  fontSize: { xs: "24px", lg: "64px" },
                  fontWeight: 400,
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  marginBottom: "10px",
                  lineHeight: { xs: "28px", lg: "45px" },
                }}
              >
                {item.value}
              </Typography>

              {/* Label Typography */}
              <Typography
                sx={{
                  fontSize: { xs: "16px", lg: "18px" },
                  fontWeight: 700,
                  fontFamily: "Recoleta medium",
                  color: "#828282",
                  lineHeight: { xs: "20px", lg: "24px" },
                }}
              >
                {item.label}
              </Typography>
            </Grid>
          ))}


        </Grid>


      </Grid>


      {/* 4th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "20px",  // Adjust padding for smaller screens
            sm: "20px",
            md: "40px",  // Adjust padding for medium screens
            lg: "80px 88px 80px 88px",
            xl: "100px",
          },
        }}
      >
        <Grid item xs={3} sm={3} md={12} lg={12}
          sx={{
            margin: {
              xs: "0px 0px 0px 15px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              // lg: "0px 0px 0px 0px",
              // xl: "0px 0px 0px 0px",
            },
          }}>
          <img
            src={sun}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "52px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              padding: {
                xs: "10px 0",
                sm: "10px 0",
                md: "15px 0",
                lg: "1px 0px 10px 0px",
                xl: "10px 0",
              },
              fontSize: {
                xs: "24px",
                sm: "34px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "center", // Adjust text alignment
            }}
          >
            The Science
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={7.3}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "0px 10px",  // Padding for smaller screens
              sm: "0px 10px",
              md: "0px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 60px 0px",
                md: "0px 0px 40px 0px",
                lg: "1px 0px 40px 0px",
                xl: "0px",
              },
              fontSize: {
                xs: "16px",  // Adjust for small screens
                sm: "32px",
                md: "18px",
                lg: "25px",
                xl: "20px",
              },
              fontWeight: "200",
              fontFamily: "Recoleta medium",
              color: "#000000",
              textAlign: "center",
              lineHeight: {
                xs: "1.2",
                sm: "1.2",
                md: "1.4",
                lg: "35px",
                xl: "1.5",
              },
            }}
          >
            Studies have shown that person-centered care can improve outcomes by enhancing engagement, reducing behavioral symptoms, and increasing residents' overall quality of life.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: {
              xs: "block",  // For small devices (xs), use block instead of flex
              sm: "block",  // For small screens (sm), use block as well
              md: "flex",   // For medium screens (md) and above, use flex
              lg: "flex",   // For large screens (lg) and above, use flex
            },
            bgcolor: "#FBF7E9",
            padding: {
              xs: "30px 0",
              sm: "10px 0",
              md: "0px",
              lg: "16px 16px 16px 16px",
              xl: "0px",
            },
          }}
        >
          {/* yellow card 3 */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "10px",
                sm: "0px 0px 0px 0px",
                md: "10px",
                lg: "16px",
                xl: "0px",
              },
            }}
          >
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "138px",
                height: "138px",
                alignItems: "left",
              }}
            />
            <Typography
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "15px 0",
                  lg: "4px 0px 16px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "20px",
                  sm: "32px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
              }}
            >
              Family Involvement
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "28px",
                  md: "20px",
                  lg: "21px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "1.4",
                  sm: "1.4",
                  md: "1.4",
                  lg: "25px",
                  xl: "1.5",
                },
              }}
            >
              Family members are an essential part of the care team, and research shows that their involvement positively impacts the quality of care and overall outcomes.
            </Typography>
          </Grid>

          {/* yellow card 2 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "10px",
                sm: "80px 0px 0px 0px",
                md: "10px",
                lg: "16px",
                xl: "0px",
              },
            }}
          >
            <img
              src="https://www.sunnyside.co/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fimg%2Fsunnyside%2Fsun_step_2.9cf15bb7871fbf4e40de3f13d9996050.png&w=256&q=75"
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "138px",
                height: "138px",
                alignItems: "left",
              }}
            />
            <Typography
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "15px 0",
                  lg: "4px 0px 16px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "20px",
                  sm: "32px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
              }}
            >
              Music Therapy and Art Therapy
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "28px",
                  md: "20px",
                  lg: "21px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "1.4",
                  sm: "1.4",
                  md: "1.4",
                  lg: "25px",
                  xl: "1.5",
                },
              }}
            >
              Music and art therapy have gained attention as effective therapies for individuals with dementia, helping to improve quality of life and emotional health.
            </Typography>
          </Grid>

          {/* yellow card 3 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "10px",
                sm: "80px 0px 60px 0px",
                md: "10px",
                lg: "16px",
                xl: "0px",
              },
            }}
          >
            <img
              src="https://www.sunnyside.co/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fimg%2Fsunnyside%2Fsun_step_3.dc56b9b02ab7e67531428e9c5d448793.png&w=256&q=75"
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "138px",
                height: "138px",
                alignItems: "left",
              }}
            />
            <Typography
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "15px 0",
                  lg: "4px 0px 16px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "20px",
                  sm: "32px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
              }}
            >
              Tailored Activities
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "28px",
                  md: "20px",
                  lg: "21px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "1.4",
                  sm: "1.4",
                  md: "1.4",
                  lg: "25px",
                  xl: "1.5",
                },
              }}
            >
              Providing tailored activities based on individual preferences and needs enhances cognitive function and emotional well-being for those with dementia.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        md={11}
        lg={12}
        sx={{
          display: "flex",
          padding: {
            xs: "0px",
            sm: "50px 0px 50px 0px",
            md: "60px 0px 80px 88px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 80px 88px",
          },
        }}
      >
        {/* Text Section */}
        <Grid
          container
          item
          xs={12}
          md={5.5}
          lg={6}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "0px",
              sm: "0px",
              md: "30px 0px 0px 30px",
              lg: "0px 0px 0px 0px",
              xl: "0px",
            },
            order: { xs: 2, lg: 1 }, // On small devices, text will be below image
          }}
        >
          <Grid item xs={10} sm={11} md={12} lg={8}>
            <Typography
              sx={{
                justifyContent: "flex-start",
                textAlign: {
                  xs: "left", // Align left for mobile
                  sm: "left", // Align left for small screens
                  md: "center", // Center for medium and larger screens
                  lg: "center",
                  xl: "center",
                },
                padding: {
                  xs: "30px 0px 0px 0px",
                  sm: "30px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "0px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "19px",
                  sm: "24px",
                  md: "24px",
                  lg: "25px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              “We founded Informed ALF because we believe that balance is key to enhancing the lives of those living with dementia. Scientific research has shown that small, thoughtful changes in daily routines and an environment designed with care can lead to significant improvements in well-being.”
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fixed typo here
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "20px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "900",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              —
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fixed typo here
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "10px 0px 0px 0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "23px",
                  xl: "24px",
                },
                fontWeight: "600",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              Nick & Ian
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alignItems: "center", // Fixed typo here
                padding: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                lineHeight: {
                  xs: "1.5",
                  sm: "1.5",
                  md: "1.5",
                  lg: "27px",
                  xl: "1.5",
                },
              }}
            >
              Informed ALF Founders
            </Typography>

            {/* <Button
              sx={{
                margin: {
                  xs: "15px 0px 30px 0px",
                  sm: "20px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "5px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
                width: {
                  xs: "100%",
                  sm: "60%",
                  md: "80%",
                  lg: "90%",
                  xl: "50%",
                },
                maxWidth: "550px",
                height: "50px",
                bgcolor: "#5DC0FE",
                alignItems: "center",
                color: "white",
                "&:hover": {
                  bgcolor: "#5EC0FF", // Background color on hover
                  color: "white", // Text color on hover
                },
                fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "18px" },
                fontWeight: "700",
                fontFamily: "poppins",
              }}
            >
              Express Interest
            </Button> */}
          </Grid>
        </Grid>
        {/* Express Interest */}
        {/* <StyledDialog open={openpop} onClose={() => setOpenpop(false)} fullWidth maxWidth="sm">
          <DialogTitle sx={{ textAlign: "center", bgcolor: "#f7f7f7" }}>
            <Typography variant="h5" fontWeight="bold" color="#125782">
              We're Here to Help
            </Typography>
            <Typography variant="body2" color="textSecondary" mt={1}>
              Please provide your details, and we’ll get in touch with you shortly.
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ padding: "20px 30px", bgcolor: "#fbf7e9" }}>
            <Box component="form" noValidate>
              <TextField
                fullWidth
                label="Full Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
                sx={{ borderRadius: "5px" }}
              />
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Phone Number (Optional)"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
              />
              <TextField
                fullWidth
                label="Relationship to Patient"
                name="relationship"
                value={formData.relationship}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
              />
              <TextField
                fullWidth
                label="Reason for Interest"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
              />
              <TextField
                fullWidth
                label="Additional Notes (Optional)"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                multiline
                rows={3}
              />
            </Box>
            <ReCAPTCHA
                  style={{ alignSelf: "center" }}
                  sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR"
                  onChange={handleCaptchaChange}
                />
          </DialogContent>

          <DialogActions sx={{ justifyContent: "center", bgcolor: "#f7f7f7", padding: "10px 20px" }}>
            <Button
              onClick={() => setOpenpop(false)}
              sx={{
                color: "#b31c31",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              Cancel
            </Button>
            <StyledButton onClick={handleSubmit}>Submit</StyledButton>
          </DialogActions>
        </StyledDialog> */}
        <StyledDialog open={openpop} onClose={() => setOpenpop(false)} fullWidth maxWidth="sm">
          <DialogTitle sx={{ textAlign: "center", bgcolor: "#f7f7f7", }}>
            <Typography variant="h5" fontWeight="bold" color="#125782">
              We're Here to Help
            </Typography>
            <Typography variant="body2" color="textSecondary" mt={1}>
              Please provide your details, and we’ll get in touch with you shortly.
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ padding: "20px 30px", bgcolor: "#fbf7e9", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box component="form" noValidate>
              <TextField
                fullWidth
                label="Full Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
                sx={{ borderRadius: "5px" }}
                inputProps={{
                  pattern: "[A-Za-z ]*", // Accepts only alphabets and spaces
                  title: "Only letters and spaces are allowed", // Optional message
                }}
                onInput={(e) => {
                  // Filter out numbers, but allow spaces
                  e.target.value = e.target.value.replace(/[^A-Za-z ]/g, ''); // Allow spaces
                }}
                error={!!errors.name}
                helperText={errors.name}
              />
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                fullWidth
                required
                label="Phone Number"
                name="phone"
                type="tel"
                value={formData.phone}
                // onChange={handleChange}
                onChange={(e) => {
                  // Remove any invalid characters (allow only numbers)
                  const value = e.target.value.replace(/[^0-9]/g, "");

                  // Limit the input to 10 digits
                  if (value.length <= 10) {
                    handleChange({ target: { name: "phone", value } });
                  }
                }}
                onKeyDown={(e) => {
                  // Prevent invalid key presses (e.g., letters) but allow navigation and editing keys
                  if (
                    !/[0-9]/.test(e.key) && // Allow only digits
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight" &&
                    e.key !== "Tab"
                  ) {
                    e.preventDefault();
                  }
                }}
                variant="outlined"
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone}

              />
              {/* <TextField
                fullWidth
                label="Relationship to Patient"
                name="relationship"
                value={formData.relationship}
                // onChange={handleChange}
                onChange={(e) => {
                  // Remove any numeric characters
                  const value = e.target.value.replace(/[0-9]/g, '');
                  handleChange({
                    target: { name: "relationship", value },
                  });
                }}
                variant="outlined"
                margin="normal"
                error={!!errors.relationship}
                helperText={errors.relationship}
              /> */}
              <TextField
                fullWidth
                label="Reason for Interest"
                name="reason"
                value={formData.reason}
                // onChange={handleChange}
                onChange={(e) => {
                  // Remove any numeric characters
                  const value = e.target.value.replace(/[0-9]/g, '');
                  handleChange({
                    target: { name: "reason", value },
                  });
                }}
                variant="outlined"
                margin="normal"
                error={!!errors.reason}
                helperText={errors.reason}
              />
              <TextField
                fullWidth
                label="Additional Notes (Optional)"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                multiline
                rows={2}
              />
            </Box>
            <ReCAPTCHA
              backgroundColor="#000"
              style={{ alignSelf: "center" }}
              sitekey="6LfMiHwqAAAAAAvG8lKB3ohapum3S2AvAGtYALMR"
              onChange={handleCaptchaChange}
            />
          </DialogContent>

          <DialogActions sx={{ justifyContent: "center", bgcolor: "#f7f7f7", padding: "10px 20px" }}>
            <Button
              onClick={handleCancel}
              sx={{
                color: "#b31c31",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              Cancel
            </Button>
            <StyledButton onClick={handleSubmit}>
              {/* Submit */}
              {isloading ? (
                <Box display="flex" alignItems="center">
                  <Typography variant="body4" marginRight={1}>
                    Submitting
                  </Typography>
                  <CircularProgress size={18} sx={{ color: '#FFFFFF' }} />
                </Box>
              ) : (
                "Submit"
              )}
            </StyledButton>
          </DialogActions>
        </StyledDialog>

        {/* Image Section */}
        <Grid
          item
          xs={11}
          sm={12}
          md={6.5}
          lg={6}
          sx={{
            margin: {
              xs: "0px 15px 0px 15px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            order: { xs: 1, lg: 2 }, // On small devices, image will be above text
          }}
        >
          <img
            src={cont5}
            alt="logo"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>


      {/* 6th Container  */}

      {/* <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "30px 0px", // Mobile devices
            sm: "30px 50px 0px 50px", // Small devices
            md: "60px 50px 50px 50px", // Medium devices
            lg: "80px 88px", // Large devices
            xl: "0px 0px", // Extra large devices
          },
        }}
      >
        <Grid item xs={12} sm={6} md={6} lg={12}>
          <img
            src={sun}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "52px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: "center", // Centering text
              fontSize: {
                xs: "24px", // Mobile devices
                sm: "24px", // Small devices
                md: "24px", // Medium devices
                lg: "48px", // Large devices
                xl: "24px", // Extra large devices
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              padding: {
                xs: "0px 0px 20px 0px", // Mobile
                sm: "0px 0px 35px 0px", // Small
                md: "0px 0px 20px 0px", // Medium
                lg: "1px 0px 10px 0px", // Large
              },
            }}
          >
            In the News
          </Typography>
        </Grid>

        <Grid container spacing={2} sx={{ display: "flex", flexWrap: "wrap", bgcolor: "#FBF7E9" }}>
          <Grid
            item
            xs={12} sm={6} md={4}
            sx={{
              bgcolor: "#FBF7E9",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              padding: { xs: "0px 0px 30px 0px", lg: "16px" },
            }}
          >
            <div
              style={{
                position: "relative",
                paddingBottom: "26.66%", // Aspect ratio of 3:2
                height: 0,
                overflow: "hidden",
                // backgroundColor: "#000000",
              }}
            >
              <img
                src={thenewyorktimes}
                alt="logo"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "24px", lg: "28px" },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: { xs: "25px", lg: "30px" },
                padding: { xs: "0px 0px 0px 0px", lg: "16px" },
              }}
            >
              Excessive Drinking Rose During the Pandemic. Here Are Ways to Cut Back.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12} sm={6} md={4}
            sx={{
              bgcolor: "#FBF7E9",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              padding: { xs: "0px 0px 30px 0px", lg: "16px" },
            }}
          >
            <div
              style={{
                position: "relative",
                paddingBottom: "26.66%",
                height: 0,
                overflow: "hidden",
                // backgroundColor: "#000000",
              }}
            >
              <img
                src={usatoday}
                alt="logo"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "24px", lg: "28px" },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: { xs: "25px", lg: "30px" },
                padding: { xs: "0px 0px 0px 0px", lg: "16px" },
              }}
            >
              Sober living for everyone is rising in popularity as Chrissy Teigen, more ditch drinking.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12} sm={6} md={4}
            sx={{
              bgcolor: "#FBF7E9",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              padding: { xs: "0px 0px 30px 0px", lg: "16px" },
            }}
          >
            <div
              style={{
                position: "relative",
                paddingBottom: "26.66%",
                height: 0,
                overflow: "hidden",
                // backgroundColor: "#000000",
              }}
            >
              <img
                src={forbes}
                alt="logo"
                style={{
                  position: "absolute",
                  top: 20,
                  left: 0,
                  width: "100%",
                  height: "50%",
                  objectFit: "contain",
                }}
              />
            </div>
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "24px", lg: "28px" },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: { xs: "25px", lg: "30px" },
                padding: { xs: "0px 0px 0px 0px", lg: "16px" },
              }}
            >
              Six Creative Takes on Dry January.
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}


      {/* 7th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center", // Fixed typo: 'alighnItems' to 'alignItems'
          padding: {
            xs: "20px 0px 0px 0px",
            sm: "50px 0px 0px 0px",
            md: "40px",
            lg: "80px 88px 80px 88px",
            xl: "0px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}
          sx={{
            margin: {
              xs: "0px  0px 30px 0px",
              sm: "0px 0px 30px 0px",
              md: "0px 0px 15px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            }
          }}
        >
          <img
            src={sun}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "52px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={10}
          sx={{
            justifyContent: "center",
            alignItems: "center", // Fixed typo: 'alighnItems' to 'alignItems'
            margin: {
              xs: "0px  0px 50px 0px",
              sm: "0px 0px 50px 0px",
              md: "0px 0px 40px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "8px 0px 0px 0px",
              xl: "0px",
            },
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "48px",
              xl: "24px",
            },
            fontWeight: "600",
            fontFamily: "Recoleta medium",
            color: "#124A2C",
            textAlign: "center",
            lineHeight: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "45px",
              xl: "0px",
            },
          }}
        >
          From the Blog
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{

            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "64px 0px 0px 0px",
              xl: "0px",
            },
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap", // Allow items to wrap in smaller screens
          }}
        >
          {[
            {
              // image: daydrink,
              image: "https://img.freepik.com/free-photo/man-suffering-from-allergy-side-view_23-2149700498.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid",
              title: "Understanding Early Signs of Dementia",
            },
            {
              image: "https://img.freepik.com/premium-photo/woman-taking-care-old-man_23-2151493341.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid",
              title: "Memory Care Tips for Loved Ones",
            },
            {
              image: "https://img.freepik.com/premium-photo/despair-frustrated-young-man-full-suit-touching-his-head-with-hands-screaming-while-standing-against-grey-background_425904-4922.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid",
              title: "How to Manage Dementia-Related Behaviors",
            },
            {
              image: "https://img.freepik.com/premium-photo/man-give-high-five-woman-rock_10069-1395.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid",
              title: "Activities to Engage Dementia Patients Daily",
            },
            {
              image: "https://img.freepik.com/free-photo/top-view-daily-routine-writing-blank-dark-surface-note-color-copybook-photo-notepad_140725-108880.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid",
              title: "Importance of Routine for Dementia Patients",
            },
          ].map((card, index) => (
            <Grid
              item
              xs={11}
              sm={4}
              md={4}
              lg={2.4}
              sx={{
                padding: {
                  xs: "0px",
                  sm: "10px",
                  md: "10px",
                  lg: "0px 20px 0px 0px",
                  xl: "0px",
                },
                textAlign: "center", // Ensures the text aligns center
              }}
              key={index}
            >
              <Grid item>
                <img
                  src={card.image}
                  alt={`logo-${index}`}
                  style={{
                    width: "100%", // Make image responsive
                    maxWidth: "202px",
                    height: "auto",
                    padding: "0px 0px 0px 0px",
                    borderRadius: "8px",
                  }}
                />
              </Grid>
              <Typography
                sx={{
                  margin: {
                    xs: "0px 0px 30px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "0px 0px 0px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  padding: "20px 0",
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124a2c",
                  textAlign: "center", // Ensure text is aligned properly on smaller screens
                  lineHeight: {
                    xs: "30px",
                    sm: "30px",
                    md: "40px",
                    lg: "35px",
                    xl: "0px",
                  },
                }}
              >
                {card.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>



      {/* 8th container */}
      {/* 8th container */}
      {/* 8th container */}
      {/* 8th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "30px 0px 30px 0px",
            sm: "30px 0px 30px 0px",
            md: "70px 78px 70px 78px",
            lg: "80px 88px 80px 88px",
            xl: "80px 88px 80px 88px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}>
          <img
            src={sun} // Use your actual image path here
            alt="logo"
            style={{
              width: "100%",
              maxWidth: "52px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: {
                xs: "20px 0px 30px 0px",
                sm: "10px  0px 35px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "48px", xl: "24px" },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
            }}
          >
            Meet Our Team
          </Typography>
        </Grid>

        {/* Loop through teamMembers array to render each card */}
        {teamMembers.map((member, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={5}
            md={4}  // Two cards per row on medium screens
            lg={4}  // Three cards per row on large screens
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: {
                xs: "0px",
                sm: "0px",
                md: "30px",
                lg: "16px",
                xl: "0px",
              },
            }}
          >
            <Grid
              item
              xs={12}

              md={12}
              lg={12}
              sx={{
                bgcolor: "#FBF7E9",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "0px 0px 65px 0px",
                  sm: "0px 0px 35px 0px",
                  md: "0px 0px 20px 0px",
                  lg: "16px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
              }}
            >
              <img
                src={member.image}
                alt={member.name}
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
              <Typography
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    xs: "35px 0px 0px 0px",
                    sm: "35px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: { xs: "0px", sm: "0px", md: "50px", lg: "30px", xl: "0px" },
                }}
              >
                {member.name}
              </Typography>
              <Typography
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    xs: "20px 0px 0px 0px",
                    sm: "20px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: { xs: "25px", sm: "30px", md: "30px", lg: "30px", xl: "0px" },
                }}
              >
                {member.position}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>


      {/* 9th container */}
      {/* 9th container */}
      {/* 9th container */}
      {/* 9th container */}


      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alignItems: "center", // Corrected typo 'alighnItems' to 'alignItems'
          bgcolor: "#ffffff",
          margin: {
            xs: "40px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "40px 0px 0px 0px",
            lg: "0px 0px 0px 0px",
            xl: "0px 0px 0px 0px",
          },
          padding: {
            xs: "0px",
            sm: "0px",
            md: "0px",
            lg: "80px 88px 80px 88px",
            xl: "0px",
          },
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center", // Corrected typo
              padding: {
                xs: "40px 0px 40px 0px",
                sm: "40px 0px 40px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: { xs: "24px", sm: "44px", md: "44px", lg: "48px", xl: "24px" },
              fontWeight: "400",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
            }}
          >
            Advisors
          </Typography>
        </Grid>

        {/* Loop for 10 cards */}
        {[...Array(10)].map((_, index) => (
          <Grid
            key={index}
            item
            xs={12}  // Use full width on small devices
            sm={6}   // Two cards per row on small devices
            md={4}   // Two cards per row on medium devices
            lg={4}   // Three cards per row on large devices
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: {
                xs: "0px 0px 20px 0px",  // Add spacing between cards on small devices
                sm: "0px 0px 30px 0px",  // Increase spacing on small screens
                md: "0px 0px 30px 0px",  // Adjust spacing on medium screens
                lg: "0px 0px 30px 0px",  // Keep consistent spacing on large screens
                xl: "0px 0px 30px 0px",
              },
              padding: {
                xs: "10px",  // Add some padding on small devices
                sm: "10px",
                md: "10px",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                bgcolor: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "20px",  // Add padding on small devices for better spacing
                  sm: "20px",
                  md: "10px",
                  lg: "16px",
                  xl: "16px",
                },
                borderRadius: "8px",  // Rounded corners for better aesthetics
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",  // Add shadow for better card appearance
              }}
            >
              <img
                src="https://images.prismic.io/sunnysidetest/600cfe76-ebc4-41f6-9671-7154444f1d04_headshot-hrishi2.jpg?auto=compress,format?w=640&q=100"
                alt="advisor"
                style={{
                  width: "100%",
                  maxWidth: "200px",  // Ensure image doesn't exceed its container width
                  height: "200px",
                  borderRadius: "50%",  // Circular image
                  objectFit: "cover",  // Ensure image scales properly
                  marginBottom: "20px",  // Add space between image and text
                }}
              />
              <Typography
                sx={{
                  margin: "0px",
                  fontSize: { xs: "20px", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: "30px",
                }}
              >
                Nick Allen
              </Typography>
            </Grid>
          </Grid>
        ))}

      </Grid>



      {/* 10th container */}
      {/* 10th container */}
      {/* 10th container */}
      {/* 10th container */}
      {/* 10th container */}


      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: "#ffe635",
          display: "flex",
          // flexDirection: { xs: "column", md: "row" }, // Stack on small screens, row on larger ones
          padding: {
            xs: "0px",
            sm: "0px",
            md: "0px 0px 0px 88px",
            lg: "0px 88px 0px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6.5}
          sx={{
            padding: {
              xs: "40px 10px 50px 10px",
              sm: "40px 30px 50px 15px",
              md: "40px 30px 50px 0px",
              lg: "10px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            // src={mobile}
            src="https://img.freepik.com/free-photo/group-people-throwing-money-office_1303-15891.jpg?ga=GA1.1.1392755177.1731477850&semt=ais_hybrid"
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "578px", // Control max width
              height: "auto", // Maintain aspect ratio
              borderRadius: "9px",
              boxShadow: "0px 40px 20px rgba(0, 0, 0, 0.25)", // Add shadow
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={5.5}
          sx={{
            padding: {
              xs: "0px",
              sm: "0px",
              md: "120px 0px 40px 0px",
              lg: "91px 0px 80px 0px",
              xl: "0px 0px 0px 0px",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: {
              xs: "center",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
              xl: "flex-start",
            }, // Align left on larger screens
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "30px", md: "44px", lg: "48px", xl: "24px" },
              fontWeight: "800",
              fontFamily: "poppins",
              color: "#125782",
              textAlign: {
                xs: "center",
                sm: "left",
                md: "left",
                lg: "left",
                xl: "left",
              },
              lineHeight: { xs: "28px", sm: "32px", md: "48px", lg: "54px", xl: "28px" },
              padding: {
                xs: "30px 30px 0px 30px",
                sm: "80px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 30px",
                xl: "0px 0px 0px 0px",
              },

            }}
          >
            Help us improve Informed ALF by taking our survey.
          </Typography>
          <Button
            onClick={() => setOpenpop(true)}
            sx={{
              justifyContent: "center",
              margin: {
                xs: "20px 0px 30px 0px",
                sm: "20px 0px 0px 0px",
                md: "20px 0px 0px 0px",
                lg: "20px 0px 0px 30px",
                xl: "20px 0px 0px 0px",
              },
              width: { xs: "70%", sm: "60%", md: "80%", lg: "69%", xl: "50%" },
              maxWidth: "550px",
              height: "55px",
              bgcolor: "#b31c31",
              color: "white",
              "&:hover": {
                bgcolor: "#b31c31",
                color: "white",
              },
              fontSize: { xs: "16px", sm: "18px", md: "18px" },
              fontWeight: "700",
              borderRadius: "10px",
              fontFamily: "poppins",
            }}
          >
            Express Interest
          </Button>
        </Grid>
      </Grid>

      {/* footer */}
      {/* footer */}
      {/* footer */}
      {/* footer */}
      {/* footer */}
      
      <Footer />
    </>
  );
};

export default About;
