import React from 'react'
import { useState } from "react";

import {
  Grid,
  Typography,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,

} from "@mui/material";
import image from "../images/logo3.png";
import Divider from "@mui/material/Divider";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook'


function Footer() {
    const [openDialog, setOpenDialog] = useState(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
      };
    const handleOpenDialog = () => {
        setOpenDialog(true);
      };
  return (
    <div>

<Grid
        container
        sx={{
          bgcolor: "white",
          margin: "0px",
          padding: {
            xs: "20px 15px",
            sm: "30px 20px",
            md: "50px 27px",
            lg: "50px 27px 20px 27px",
          },
        }}
       >
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "white",
            margin: "0px",
            padding: {
              xs: "20px 15px",
              sm: "30px 20px",
              md: "40px 50px 80px 50px",
              lg: "0px 0px 20px 50px",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "white",
              color: "green",
              margin: "0px 0px 40px 0px",
              height: "60px",
              width: "290px",
              display: "flex",
              textAlign: "left",
            }}
          >
            <img
              src={image}
              alt="logo"
              style={{ width: "70px", height: "60px" }}
            />
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                fontWeight: "bold",
                color: "#125782",
                padding: "10px 0px 0px 5px",
              }}
            >
              Informed ALF
            </Typography>
          </Grid>

          <Grid
            container
            spacing={4}
            sx={{
              bgcolor: "white",
              color: "black",
              padding: "0px",
              textAlign: "left",
            }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={2}
              sx={{
                bgcolor: "white",
                margin: { xs: "0px 0px", md: "0px 100px 0px 0px" },
              }}
            >
              <Link
                // href="/about"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: 600,
                  // make it responsive fontsize
                  // fontSize: "25px",
                  fontSize: { xs: "15px", md: "20px", lg: "20px", xl: "23px" },

                  height: "32px",
                }}
              >
                Explore
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Link
                href="/"
                sx={{
                  margin: {
                    xs: "0px 0px px 0px",
                    sm: "0px 0px px 0px",
                    md: "0px 0px px 0px",
                    lg: "0px 0px px 0px",
                    xl: "0px 0px px 0px"
                  },
                  textDecoration: "none",
                  color: "black",
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" },
                  height: "32px",
                }}
              >
                Home
              </Link>
              <br />
              <Link
                href="/about"
                sx={{
                  margin: {
                    xs: "0px 0px 4px 0px",
                    sm: "0px 0px 4px 0px",
                    md: "0px 0px 4px 0px",
                    lg: "0px 0px 4px 0px",
                    xl: "0px 0px 4px 0px"
                  },
                  textDecoration: "none",
                  color: "black",
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" },
                  height: "32px",
                }}
              >
                About Us
              </Link>
              <br />
              <Link
                href="/Help"
                sx={{
                  margin: {
                    xs: "0px 0px 4px 0px",
                    sm: "0px 0px 4px 0px",
                    md: "0px 0px 4px 0px",
                    lg: "0px 0px 4px 0px",
                    xl: "0px 0px 4px 0px"
                  },
                  textDecoration: "none",
                  color: "black",
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" },
                  height: "32px",
                }}
              >
                Help & Support
              </Link>
              <br />
              <Link
                href="/contact"
                sx={{
                  margin: {
                    xs: "0px 0px 4px 0px",
                    sm: "0px 0px 4px 0px",
                    md: "0px 0px 4px 0px",
                    lg: "0px 0px 4px 0px",
                    xl: "0px 0px 4px 0px"
                  },
                  textDecoration: "none",
                  color: "black",
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" },
                }}
              >
                Contact Us
              </Link>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={2}
              sx={{
                bgcolor: "white",
                margin: { xs: "0px", md: "0px 100px 0px 0px" },
                textAlign: "left",
              }}
            >
              <Typography
                // href="#"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: 600,
                  fontSize: { xs: "15px", md: "20px", lg: "20px", xl: "23px" },
                }}
              >
                Get in Touch
              </Typography>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Link
                href="https://www.instagram.com/informedalf?igsh=MWpuYXFvYmd6YW5zZg=="
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "inline-flex", // Ensure the link contents align inline
                  alignItems: "center", // Center icon and text vertically
                  gap: "8px", // Add space between icon and text
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, // Text size
                }}
              >
                <InstagramIcon sx={{ fontSize: { xs: "20px", md: "24px" }, color: "black" }} />
                <span>Instagram</span>
              </Link>
              
              <br />
              <Link
                href="https://x.com/InformedAlf"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "inline-flex", // Ensure the link contents align inline
                  alignItems: "center", // Center icon and text vertically
                  gap: "8px", // Add space between icon and text
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, // Text size
                }}
              >
                <TwitterIcon sx={{ fontSize: { xs: "20px", md: "24px" }, color: "black" }} />
                <span>X</span>
              </Link>
             
              <br />
              <Link
                href="https://www.youtube.com/channel/UCsB14X9mnpALoHPE6JqxSwA"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "inline-flex", // Ensure the link contents align inline
                  alignItems: "center", // Center icon and text vertically
                  gap: "8px", // Add space between icon and text
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, // Text size
                }}
              >
                <YouTubeIcon sx={{ fontSize: { xs: "20px", md: "24px" }, color: "black" }} />
                <span>Youtube</span>
              </Link>
              
              <br />
              <Link
                href="https://www.facebook.com/profile.php?id=61568607891886&sk=about"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "inline-flex", // Ensure the link contents align inline
                  alignItems: "center", // Center icon and text vertically
                  gap: "8px", // Add space between icon and text
                  fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, // Text size
                }}
              >
                <FacebookIcon sx={{ fontSize: { xs: "20px", md: "24px" }, color: "black" }} />
                <span>Facebook</span>
              </Link>
              <br />

            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{
                bgcolor: "white",
                color: "black",
                margin: "0px",
                textAlign: "left",
                padding: { xs: "0px 0px 0px 0px", md: "0px 0px 0px 0px" },
              }}
            >
              <Link
                // href="#"
                sx={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "600",
                  fontSize: { xs: "15px", md: "20px", lg: "20px", xl: "23px" },
                }}
              >
                About Informed ALF
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Typography sx={{ color: "black", fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, }}>
                Informed ALF was created to provide ease of access to important and personal information about each person living in assisted living in order to provide individualized and informed care coordination
              </Typography>
              <br />
              <Typography sx={{ color: "black", fontSize: { xs: "15px", md: "15px", lg: "16px", xl: "17px" }, }}>
                Informed ALF safeguards the person's preferences, legal
                paperwork, medical, emotional, and physical history,
                personalized tips and techniques for informed personal care, and
                so much more. This app will revolutionize care for those unable
                to recall in everyday or emergency situations.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "white",
              color: "black",
              margin: "40px 0px 40px 0px",
              textAlign: "left",
            }}
          >
            <Divider sx={{ margin: "50px 0px 20px 0px", color: "black" }} />

            <Grid
              container
              sx={{
                bgcolor: "white",
                display: "flex",
                margin: "30px 0px 0px 0px",
              }}
            >
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ bgcolor: "white", display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#919191",
                    fontSize: { xs: "12px", md: "14px" },
                    margin: "0px",
                  }}
                >
                  Copyright 2024 Informed ALF. All rights reserved.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                lg={8}
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: { xs: "right",md: "right",sm: "right", lg: "right" },
                  fontSize: { xs: "12px", md: "14px" },
                  margin: "0px",
                }}
                style={{ color: "#919191" }}
              >
                <Link
                  href="/policy"
                  style={{ color: "#919191", marginRight: "10px" }}
                >
                  Privacy Policy
                </Link>
                {/* |
                <Link href="/Terms" style={{ color: "#919191", margin: "0px 10px" }}>
                  Terms & Conditions
                </Link> */}
                
                {/* <Link onClick={handleOpenDialog} style={{ color: "#919191", marginLeft: "10px", cursor: "pointer" }}>
                  Cookie Policy
                </Link> */}

                {/* Cookie Policy Dialog */}
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  maxWidth="sm"
                  fullWidth
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle>Cookie Policy</DialogTitle>
                  <Divider />

                  <DialogContent sx={{ minHeight: "350px" }}>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      This website uses cookies to enhance user experience, personalize content,
                      and analyze our traffic. By using our site, you agree to our use of cookies.
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      Cookies are small data files stored on your device. They help us improve
                      our services and provide a more personalized experience. You may disable cookies
                      through your browser settings, but this may affect your experience on our site.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      At Informed ALF, we understand the importance of privacy and safeguarding sensitive
                      information, especially for individuals with dementia and their caregivers. Our cookie policy
                      is designed to provide transparency about the data we collect and how we use it to enhance the user experience.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      We use cookies to track user interactions on our site, which allows us to offer a more tailored experience for dementia patients,
                      their families, and caregivers. For instance, cookies help us remember your preferences for easy navigation, and they enable
                      the personalization of health-related content based on your needs. Some cookies are essential for the functionality of our website, such as
                      those that enable you to access secured areas or submit forms.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      Additionally, we use cookies to collect analytics data to understand how visitors use our website. This helps us improve
                      the accessibility of information and resources for dementia patients, caregivers, and healthcare professionals.
                      If you have concerns about the use of cookies on our website, you may manage or disable cookies through your browser settings.
                      Please note that disabling certain cookies may limit your ability to use some features on our site.
                    </Typography>

                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      By continuing to use our website, you consent to our use of cookies as outlined in this policy. We may update our cookie policy from
                      time to time, so we encourage you to review it periodically to stay informed.
                    </Typography>
                  </DialogContent>

                  {/* Button for agreeing to cookies */}
                  <DialogActions sx={{ justifyContent: "center", margin: "0px 50px 0px 50px" }}>
                    <Button
                      color="success"
                      sx={{
                        margin: "0px 10px",
                        borderRadius: "10px",
                        width: "50%", // Adjust width for balance
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Agree
                    </Button>

                    {/* Button for rejecting cookies */}
                    <Button
                      color="error"
                      sx={{

                        margin: "0px 10px",
                        borderRadius: "10px",
                        width: "50%", // Adjust width for balance
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Reject
                    </Button>
                  </DialogActions>

                  <DialogActions sx={{ justifyContent: "center" }}>
                    <Button
                      sx={{
                        backgroundColor: "#b31c31",
                        "&&:hover": {
                          backgroundColor: "#b31c31",
                        },
                        width: {
                          xs: "100%", // Full width on extra-small screens (mobile)
                          sm: "80%",  // 80% width on small screens and above
                          md: "80%",  // 60% width on medium screens and above
                        },
                        margin: {
                          xs: "0px 0px 20px 0px", // Margin for extra-small screens
                          sm: "0px 0px 15px 0px", // Adjusted margin for small screens and above
                          md: "0px 0px 15px 0px", // Adjusted margin for medium screens and above
                        },
                        borderRadius: "10px",
                      }}
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>



              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
       >
        <Alert onClose={handleCloseSnackbar} severity={snackbarMessage.includes('Error') ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar> */}
      </Grid>
    </div>
  )
}

export default Footer